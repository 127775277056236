<template>
    <div v-if="show" class="nevs-popup-container">
        <div class="nevs-popup">
            <div class="popup-form">
                <div class="e-invoices-disclaimer">{{ $LANG.Get('texts.eInvoicesDisclaimer') }}</div>
                <NevsSelect :label="$LANG.Get('fields.invoiceType') + ':'" v-model="data.type" :ajax="'select/e-invoice-types'"></NevsSelect>
                <NevsSelect :label="$LANG.Get('fields.invoiceProfile') + ':'"  v-model="data.profile" :ajax="'select/e-invoice-profiles'"></NevsSelect>
                <NevsTextField :label="$LANG.Get('fields.customerEmail') + ':'"  v-model="data.email" :error="validation.email" :options="customerTaxOptions"></NevsTextField>
            </div>
            <div class="nevs-popup-buttons">
                <NevsButton class="error" @click="cancelClick()">
                    <i class="fa-solid fa-times"></i>
                    {{ $LANG.Get('buttons.cancel') }}
                </NevsButton>
                <NevsButton class="success" @click="sendClick()">
                    <i class="fa-solid fa-paper-plane"></i>
                    {{ $LANG.Get('buttons.send') }}
                </NevsButton>&nbsp;
            </div>
        </div>
    </div>
</template>

<script>
import NevsButton from "@/components/nevs/NevsButton.vue";
import NevsSelect from "@/components/nevs/NevsSelect.vue";
import NevsTextField from "@/components/nevs/NevsTextField.vue";

export default {
    name: "ModalEInvoice",
    components: {NevsTextField, NevsSelect, NevsButton},
    data() {
        return {
            data: {
                profile: 'P1',
                type: '380',
                email: ''
            },
            validation: {
                email: ''
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        invoiceId: {
            type: Number,
            default: null,
        }
    },
    emits: ['done'],
    watch: {
        'show'() {
            this.data = {
                profile: 'P1',
                type: '380',
                email: ''
            };
            this.validation = {
                email: ''
            };
            if (this.show && this.invoiceId != null) {
                let vm = this;
                this.$API.APICall('get', 'invoices/e-invoice-email/' + this.invoiceId, {}, (data, success) => {
                    if (success) {
                        vm.data.email = data.email;
                    }
                });
            }
        }
    },
    methods: {
        cancelClick() {
            this.$emit('done', false);
        },
        sendClick() {
            let valid = true;

            if (!this.$HELPERS.CheckEmail(this.data.email)) {
                valid = false;
                this.validation.email = this.$LANG.Get('labels.invalidEmailAddress');
            } else {
                this.validation.email = '';
            }

            if (valid) {
                let vm = this;
                this.$LOCAL_BUS.TriggerEvent('popup', {
                    type: 'confirm', text: this.$LANG.Get('alerts.eInvoiceQuestion'), callback: (response) => {
                        if (response) {
                            vm.$API.APICall('post', 'invoices/e-invoice/' + this.invoiceId, this.data, (data, success) => {
                                if (success) {
                                    this.$emit('done', true);
                                } else {
                                    if (data.message !== undefined) {
                                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                                            text: data.message,
                                            type: 'alert'
                                        });
                                    } else {
                                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                                            text: vm.$LANG.Get('alerts.serverError'),
                                            type: 'alert'
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
            }
        }
    }
}
</script>


<style scoped>

.popup-form {
    padding: 10px;
}

.e-invoices-disclaimer {
    padding: 5px;
    margin-bottom: 10px;
}

</style>