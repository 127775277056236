import moment from "moment/moment";
import Settings from "@/config.json";

export default {
    generateHelpers: function () {
        return {
            GetCookie(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            SetCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            CheckEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            CheckOIB(oib) {
                oib = oib.toString();
                if (oib.length !== 11) return false;

                var b = parseInt(oib, 10);
                if (isNaN(b)) return false;

                var a = 10;
                for (var i = 0; i < 10; i++) {
                    a = a + parseInt(oib.substr(i, 1), 10);
                    a = a % 10;
                    if (a === 0) a = 10;
                    a *= 2;
                    a = a % 11;
                }
                var control = 11 - a;
                if (control === 10) control = 0;

                return control === parseInt(oib.substr(10, 1));
            },
            GetYesNoAllOptions(vm) {
                return [
                    {
                        label: vm.$LANG.Get('options.yes'),
                        value: 1
                    },
                    {
                        label: vm.$LANG.Get('options.no'),
                        value: 0
                    },
                    {
                        label: vm.$LANG.Get('options.all'),
                        value: -1
                    }
                ]
            },
            DatePattern() {
                return /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;
            },
            DateTimePattern() {
                return /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])) (?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/
            },
            FormatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            },
            FormatDate(date) {
                let dateArray = date.split('-');
                return dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0] + '.';
            },
            ToCroatianLower(s) {
                let swaps = {
                    'c': ['č', 'Č', 'ć', 'Ć'],
                    'd': ['đ', 'Đ'],
                    's': ['š', 'Š'],
                    'z': ['ž', 'Ž']
                }
                for (let to in swaps) {
                    for (let from of swaps[to]) {
                        s = s.split(from).join(to);
                    }
                }
                s = s.toLowerCase();
                return s;
            },
            GetDefaultCurrency() {
                if (moment().format('YYYY-MM-DD HH:mm:ss') < Settings.EUR_TRANSITION) {
                    return 'HRK';
                }
                return 'EUR';
            }
        }

    }
}