<template>
    <div class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="primary help-add" @click="addClick">
                <i class="fa-solid fa-plus"></i>
                {{ $LANG.Get('buttons.add') }}
            </NevsButton>
        </div>
        <NevsCard>
            <NevsTable
                :default-sort="tableData.defaultSort"
                :fields="tableData.fields"
                :height="tableData.height"
                :total-records="tableData.totalRecords"
                @reload="reloadTable">
                <tr class="nevs-table-filters">
                    <td>
                        <NevsTextField v-model="tableData.filters.name"></NevsTextField>
                    </td>
                    <td>
                        <NevsTextField v-model="tableData.filters.oib"></NevsTextField>
                    </td>
                    <td>
                        <NevsSelect v-model="tableData.filters.active"
                                    :options="$HELPERS.GetYesNoAllOptions(this)"></NevsSelect>
                    </td>
                    <td></td>
                </tr>
                <tr v-for="(item, key) in tableData.records" :key="key">
                    <td class="nevs-table-linked-cell help-edit" @click="openClick(item.id)">{{ item.name }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.oib }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.active_display }}</td>
                    <td class="nevs-table-actions">
            <span :title="$LANG.Get('tooltips.openInNewWindow')" class="nevs-table-button help-edit-new"
                  @click="openClick(item.id, true)"><i class="fa-solid fa-up-right-from-square"></i></span>
                    </td>
                </tr>
            </NevsTable>
        </NevsCard>
    </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTable from "@/components/nevs/NevsTable";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsSelect from "@/components/nevs/NevsSelect";

export default {
    name: "ModuleCompanies",
    components: {NevsSelect, NevsTextField, NevsTable, NevsButton, NevsCard},
    data() {
        return {
            tableData: {
                fields: [
                    {
                        name: 'name',
                        label: this.$LANG.Get('fields.name')
                    },
                    {
                        name: 'oib',
                        label: this.$LANG.Get('fields.oib')
                    },
                    {
                        name: 'active',
                        width: '70px',
                        label: this.$LANG.Get('fields.activeF')
                    },
                    {
                        name: 'actions',
                        width: '50px',
                        label: ''
                    }
                ],
                filters: {
                    name: '',
                    oib: '',
                    active: 1
                },
                records: [],
                lastRequest: null,
                totalRecords: 0,
                defaultSort: {
                    field: 'name',
                    descending: false
                },
                filterTimer: null,
                height: '0px'
            }
        }
    },
    watch: {
        'tableData.filters': {
            handler() {
                if (this.filterTimer !== null) {
                    clearTimeout(this.filterTimer);
                }
                let vm = this;
                this.filterTimer = setTimeout(function () {
                    vm.reloadTable(vm.tableData.lastRequest);
                    vm.filterTimerOn = false;
                }, 500);
            },
            deep: true
        }
    },
    methods: {
        openClick(id, newWindow = false) {
            if (newWindow) {
                let routeData = this.$router.resolve('/companies/' + id);
                window.open(routeData.href);
            } else {
                this.$router.push('/companies/' + id);
            }
        },
        addClick() {
            this.$router.push('/companies/0');
        },
        reloadTable(request) {
            let vm = this;
            request.filters = this.tableData.filters;
            this.tableData.lastRequest = JSON.parse(JSON.stringify(request));
            this.$API.APICall('get', 'companies', request, (data, success) => {
                if (success) {
                    vm.tableData.records = data.records;
                    vm.tableData.totalRecords = data.total_records;
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        resolveWindowResize() {
            this.tableData.height = window.innerHeight - 205 + 'px';
        }
    },
    mounted() {
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-add',
                html: this.$LANG.Get('help.add'),
                left: 0,
                top: 0,
                width: 300
            },
            {
                class: 'help-edit-new',
                html: this.$LANG.Get('help.editNewWindow'),
                left: -306,
                top: 0,
                width: 300
            },
            {
                class: 'help-edit',
                html: this.$LANG.Get('help.edit'),
                left: 0,
                top: 0,
                width: 300
            }
        ]);
        this.$store.commit('selectMenu', 'clients');
        this.$store.commit('selectSubMenu', 'companies');
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.companies'),
                link: null
            }
        ]);
        window.addEventListener('resize', this.resolveWindowResize);
        this.resolveWindowResize();
        let vm = this;
        this.$CROSS_TAB_BUS.ListenToEvent('reload-companies', () => {
            vm.reloadTable(vm.tableData.lastRequest);
        });
    }
}
</script>

<style scoped>
</style>