<template>
    <div v-if="loaded" class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success help-save" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
            <NevsButton class="error help-back" @click="backClick"><i class="fa-solid fa-arrow-left"></i> {{
                    $LANG.Get('buttons.back')
                }}
            </NevsButton>
        </div>
        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.generalData') }}</h2>
            <NevsTextField v-model="person.first_name" :error="personValidation.first_name"
                           :label="'*' + $LANG.Get('fields.firstName') + ':'"></NevsTextField>
            <NevsTextField v-model="person.last_name" :error="personValidation.last_name"
                           :label="'*' + $LANG.Get('fields.lastName') + ':'"></NevsTextField>
            <NevsTextField v-model="person.oib" :error="personValidation.oib"
                           :label="$LANG.Get('fields.oib') + ':'"></NevsTextField>
            <NevsCheckbox class="help-foreign" v-model="person.foreign" :label="$LANG.Get('fields.foreignClient')"></NevsCheckbox>
            <NevsTextField v-if="person.foreign" v-model="person.oib_label" :error="personValidation.oib_label"
                           :label="$LANG.Get('fields.oibLabel') + ':'"></NevsTextField>
            <NevsCheckbox class="help-active" v-model="person.active" :label="$LANG.Get('fields.activeF')"></NevsCheckbox>
        </NevsCard>
        <NevsCard class="form form-float">
            <h2 class="help-emails">{{ $LANG.Get('labels.emails') }}</h2>
            <div v-if="personValidation.emails" class="box-error nevs-text-error">{{ personValidation.emails }}</div>
            <div v-for="(email, key) in person.emails" :key="key" class="box-form">
                <NevsTextField v-model="email.address" :error="email.validation"
                               :label="'*' + $LANG.Get('fields.address') + ':'"></NevsTextField>
                <NevsCheckbox v-model="email.offers" :label="$LANG.Get('fields.emailOffers')"></NevsCheckbox>
                <NevsCheckbox v-model="email.invoices" :label="$LANG.Get('fields.emailInvoices')"></NevsCheckbox>
                <span :title="$LANG.Get('tooltips.removeItem')" class="box-form-remove-button"
                      @click="removeRecord(key, 'emails')"><i class="fa-solid fa-trash"></i></span>
            </div>
            <NevsButton @click="addRecord('emails')">{{ $LANG.Get('buttons.add') }}</NevsButton>
        </NevsCard>
        <NevsCard class="form form-float">
            <h2>{{ $LANG.Get('labels.phones') }}</h2>
            <div v-if="personValidation.phones" class="box-error nevs-text-error">{{ personValidation.phones }}</div>
            <div v-for="(phone, key) in person.phones" :key="key" class="box-form">
                <NevsTextField v-model="phone.name" :error="phone.validation.name"
                               :label="'*' + $LANG.Get('fields.name') + ':'"></NevsTextField>
                <NevsTextField v-model="phone.number" :error="phone.validation.number"
                               :label="'*' + $LANG.Get('fields.number') + ':'"></NevsTextField>
                <br/><br/><br/>
                <span :title="$LANG.Get('tooltips.removeItem')" class="box-form-remove-button"
                      @click="removeRecord(key, 'phones')"><i class="fa-solid fa-trash"></i></span>
            </div>
            <NevsButton @click="addRecord('phones')">{{ $LANG.Get('buttons.add') }}</NevsButton>
        </NevsCard>
        <NevsCard class="form form-float">
            <h2 class="help-addresses">{{ $LANG.Get('labels.addresses') }}</h2>
            <div v-if="personValidation.addresses" class="box-error nevs-text-error">{{
                    personValidation.addresses
                }}
            </div>
            <div v-for="(address, key) in person.addresses" :key="key" class="box-form">
                <NevsTextArea v-model="address.address" :error="address.validation.address"
                              :label="$LANG.Get('fields.streetAndHouseNo')"></NevsTextArea>
                <NevsTextField v-model="address.postal_code" :error="address.validation.postal_code"
                               :label="$LANG.Get('fields.postalCode')"></NevsTextField>
                <NevsTextField v-model="address.city" :error="address.validation.city"
                               :label="$LANG.Get('fields.city')"></NevsTextField>
                <NevsTextField v-model="address.country" :error="address.validation.country"
                               :label="$LANG.Get('fields.country')"></NevsTextField>
                <NevsCheckbox v-model="address.official" :label="$LANG.Get('fields.officialF')"></NevsCheckbox>
                <span :title="$LANG.Get('tooltips.removeItem')" class="box-form-remove-button"
                      @click="removeRecord(key, 'addresses')"><i class="fa-solid fa-trash"></i></span>
            </div>
            <NevsButton @click="addRecord('addresses')">{{ $LANG.Get('buttons.add') }}</NevsButton>
        </NevsCard>
    </div>
</template>

<script>
import Person from '@/models/Person';
import NevsCard from "@/components/nevs/NevsCard";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTextArea from "@/components/nevs/NevsTextArea";

export default {
    name: "EntityPerson",
    components: {NevsTextArea, NevsButton, NevsCheckbox, NevsTextField, NevsCard},
    data() {
        return {
            loaded: false,
            mode: this.$route.params.id === '0' ? 'add' : 'edit',
            person: new Person({id: this.$route.params.id}),
            personValidation: new Person({}, true),
            templates: {
                emails: {
                    address: '',
                    invoices: false,
                    offers: false,
                    validation: ''
                },
                phones: {
                    number: '',
                    name: '',
                    validation: {
                        number: '',
                        name: ''
                    }
                },
                addresses: {
                    address: '',
                    postal_code: '',
                    city: '',
                    country: '',
                    official: false,
                    validation: {
                        address: '',
                        postal_code: '',
                        city: '',
                        country: ''
                    }
                }
            }
        }
    },
    watch: {
        'person.foreign'() {
            if (this.loaded) {
                this.person.oib_label = 'OIB';
            }
        }
    },
    methods: {
        removeRecord(key, type) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
                    if (response) {
                        vm.person[type].splice(key, 1);
                    }
                }
            });
        },
        addRecord(type) {
            this.person[type].push(JSON.parse(JSON.stringify(this.templates[type])))
        },
        backClick() {
            if (window.history.length === 1) {
                window.close();
            } else {
                this.$router.back();
            }
        },
        saveClick() {
            if (this.person.addresses.length === 0) {
                this.person.addresses.push(this.templates.addresses);
            }
            if (this.person.addresses.length === 1) {
                this.person.addresses[0].official = true;
            }

            let valid = true;

            if (this.person.first_name === '') {
                valid = false;
                this.personValidation.first_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.personValidation.first_name = '';
            }

            if (this.person.last_name === '') {
                valid = false;
                this.personValidation.last_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.personValidation.last_name = '';
            }

            if (this.person.oib !== '') {
                if (!this.person.foreign) {
                    if (!this.$HELPERS.CheckOIB(this.person.oib)) {
                        valid = false;
                        this.personValidation.oib = this.$LANG.Get('labels.invalidOIB');
                    } else {
                        this.personValidation.oib = '';
                    }
                } else {
                    this.personValidation.oib = '';
                }
            }

            if (this.person.emails.length !== 0) {
                let invoices = false;
                let offers = false;
                for (let email of this.person.emails) {
                    if (email.invoices) {
                        invoices = true;
                    }
                    if (email.offers) {
                        offers = true;
                    }
                    if (email.address === '') {
                        valid = false;
                        email.validation = this.$LANG.Get('labels.mandatoryField');
                    } else {
                        if (!this.$HELPERS.CheckEmail(email.address)) {
                            valid = false;
                            email.validation = this.$LANG.Get('labels.invalidEmailAddress');
                        } else {
                            email.validation = '';
                        }
                    }
                }
                if (!(invoices && offers)) {
                    valid = false;
                    this.personValidation.emails = this.$LANG.Get('labels.defaultEmailFail');
                } else {
                    this.personValidation.emails = '';
                }
            } else {
                this.personValidation.emails = '';
            }

            let official = 0;
            for (let address of this.person.addresses) {
                if (address.official) {
                    official++;
                }
            }
            if (official !== 1) {
                valid = false;
                this.personValidation.addresses = this.$LANG.Get('labels.defaultAddressFail');
            } else {
                this.personValidation.addresses = '';
            }

            for (let phone of this.person.phones) {
                if (phone.name === '') {
                    valid = false;
                    phone.validation.name = this.$LANG.Get('labels.mandatoryField');
                } else {
                    phone.validation.name = '';
                }
                if (phone.number === '') {
                    valid = false;
                    phone.validation.number = this.$LANG.Get('labels.mandatoryField');
                } else {
                    phone.validation.number = '';
                }
            }

            if (valid) {
                let vm = this;
                let requestMethod = this.mode === 'add' ? 'post' : 'put';
                let requestService = this.mode === 'add' ? 'persons' : 'persons/' + this.person.id;
                this.$API.APICall(requestMethod, requestService, this.person, (data, success) => {
                    if (success) {
                        vm.$CROSS_TAB_BUS.TriggerEvent('reload-persons', {});
                        vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        vm.backClick();
                    } else {
                        if (data.error === 'oib_duplicate') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.oibDuplicate'),
                                type: 'alert'
                            });
                        } else {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.serverError'),
                                type: 'alert'
                            });
                        }
                    }
                })
            }
        }
    },
    mounted() {
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-save',
                html: this.$LANG.Get('help.save'),
                left: -100,
                top: 0,
                width: 150
            },
            {
                class: 'help-back',
                html: this.$LANG.Get('help.back'),
                left: 85,
                top: -30,
                width: 300
            },
            {
                class: 'help-emails',
                html: this.$LANG.Get('help.emails'),
                left: 0,
                top: 0,
                width: 300
            },
            {
                class: 'help-addresses',
                html: this.$LANG.Get('help.addresses'),
                left: 0,
                top: 0,
                width: 300
            },
            {
                class: 'help-foreign',
                html: this.$LANG.Get('help.foreignPerson'),
                left: 200,
                top: -60,
                width: 400
            },
            {
                class: 'help-active',
                html: this.$LANG.Get('help.activePerson'),
                left: 200,
                top: -40,
                width: 500
            }
        ]);
        this.$store.commit('selectMenu', 'clients');
        this.$store.commit('selectSubMenu', 'persons');

        if (this.mode === 'add') {
            this.$store.commit('setBreadcrumbs', [
                {
                    label: this.$LANG.Get('modules.persons'),
                    link: '/persons'
                },
                {
                    label: this.$LANG.Get('modules.newPerson'),
                    link: null
                }
            ]);
            this.loaded = true;
        } else {
            let vm = this;
            this.$API.APICall('get', 'persons/' + this.person.id, {}, (data, success) => {
                if (success) {
                    if (data.person !== null) {
                        vm.person.Fill(data.person);
                        vm.$store.commit('setBreadcrumbs', [
                            {
                                label: vm.$LANG.Get('modules.persons'),
                                link: '/persons'
                            },
                            {
                                label: vm.person.first_name + ' ' + vm.person.last_name,
                                link: null
                            }
                        ]);
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
                vm.$nextTick(() => {
                    vm.loaded = true;
                });
            });
        }
    }
}
</script>

<style scoped>
.form {
    max-width: 300px;
    margin-bottom: 10px;
    padding: 15px;
}

.form-float {
    width: 300px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 15px;
}

.form-float h2 {
    margin: 0 0 5px;
}

.box-form {
    background: #c6d8e2;
    border-radius: 5px;
    margin-bottom: 5px;
    position: relative;
}

.box-error {
    font-size: 12px;
    margin-bottom: 5px;
    text-align: justify;
}

.box-form-remove-button {
    cursor: pointer;
    position: absolute;
    right: 5px;
    bottom: 5px;
}
</style>