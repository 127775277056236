<template>
    <div v-if="show" class="help-overlay">
        <NevsButton @click="closeClick" class="error help-overlay-close-button">{{ $LANG.Get('buttons.closeHelpOverlay') }}</NevsButton>
        <div v-if="isEmpty" class="help-overlay-empty">{{ $LANG.Get('help.noItems') }}</div>
    </div>
</template>

<script>
import NevsButton from "@/components/nevs/NevsButton.vue";

export default {
    name: "HelpOverlay",
    components: {NevsButton},
    data() {
        return {
            openElement: null,
            isEmpty: false
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    emits: ['done'],
    watch: {
        'show'() {
            if (this.show) {
                let set = false;
                for (let item of this.items) {
                    let element = document.querySelector('.' + item.class);
                    if (element === null) continue;
                    set = true;
                    let rect = element.getBoundingClientRect();
                    let mainDiv = document.createElement('div');
                    mainDiv.classList.add('help-overlay-item');
                    mainDiv.style.left = rect.left + 'px';
                    mainDiv.style.top = rect.top + 'px';
                    mainDiv.style.width =  rect.width + 'px';
                    mainDiv.style.height =  rect.height + 'px';
                    mainDiv.style.position = 'absolute';
                    mainDiv.style.background = 'rgba(255, 0, 0, 0.5)';
                    mainDiv.style.zIndex = 98;
                    document.body.appendChild(mainDiv);

                    let detailsDiv = document.createElement('div');
                    detailsDiv.classList.add('help-overlay-item');
                    detailsDiv.style.left = (rect.left + item.left) + 'px';
                    detailsDiv.style.top = (rect.top + rect.height + item.top) + 'px';
                    detailsDiv.style.width =  item.width + 'px';
                    detailsDiv.style.height = 'auto';
                    detailsDiv.style.position = 'absolute';
                    detailsDiv.style.padding = '10px';
                    detailsDiv.style.background = 'rgba(247, 99, 0)';
                    detailsDiv.style.color = 'black';
                    detailsDiv.style.zIndex = 99;
                    detailsDiv.innerHTML = item.html;
                    document.body.appendChild(detailsDiv);
                    this.openElement = 'help-overlay-item-' + item.class;
                }
                this.isEmpty = !set;
            } else {
                this.isEmpty = false;
                var elements = document.querySelectorAll('.help-overlay-item');
                elements.forEach(function(element) {
                    element.remove();
                });
            }
        }
    },
    methods: {
        closeClick() {
            this.$emit('done');
        }
    }
}
</script>

<style scoped>
    .help-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 98;
    }
    .help-overlay-close-button {
        position: fixed;
        right: 10px;
        top: 10px;
    }
    .help-overlay-empty {
        background: rgba(247, 99, 0);
        color: black;
        width: 300px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        text-align: center;
        padding: 20px;
        font-size: 20px;
    }
</style>