<template>
    <div v-if="loaded" class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success help-save" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
            <NevsButton class="error help-back" @click="backClick"><i class="fa-solid fa-arrow-left"></i> {{
                    $LANG.Get('buttons.back')
                }}
            </NevsButton>
        </div>
        <NevsCard class="form">
            <NevsTextField v-model="user.first_name" :error="userValidation.first_name"
                           :label="'*' + $LANG.Get('fields.firstName') + ':'"></NevsTextField>
            <NevsTextField v-model="user.last_name" :error="userValidation.last_name"
                           :label="'*' + $LANG.Get('fields.lastName') + ':'"></NevsTextField>
            <NevsTextField v-model="user.email" :error="userValidation.email"
                           :label="'*' + $LANG.Get('fields.email') + ':'"></NevsTextField>
            <NevsTextField v-model="user.oib" :error="userValidation.oib"
                           :label="$LANG.Get('fields.oib') + ':'"></NevsTextField>
            <template v-if="$store.state.user.permissions.includes('ADMINISTRATOR')">
                <NevsMultipleSelect v-model="user.permissions" :ajax="'select/permissions'"
                                    :label="$LANG.Get('fields.permissions') + ':'"></NevsMultipleSelect>
                <NevsCheckbox v-model="user.active" :label="$LANG.Get('fields.activeM')"></NevsCheckbox>
            </template>
            <NevsCheckbox v-if="mode === 'edit'" v-model="changePassword" class="help-password"
                          :label="$LANG.Get('labels.changePassword')"></NevsCheckbox>
            <NevsTextField v-if="mode === 'add' || changePassword"
                           v-model="user.password" :error="userValidation.password"
                           :label="'*' + $LANG.Get('fields.password') + ':'"></NevsTextField>
        </NevsCard>
    </div>
</template>

<script>
import User from '@/models/User';
import NevsCard from "@/components/nevs/NevsCard";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";
import NevsButton from "@/components/nevs/NevsButton";
import NevsMultipleSelect from "@/components/nevs/NevsMultipleSelect";

export default {
    name: "EntityUser",
    components: {NevsMultipleSelect, NevsButton, NevsCheckbox, NevsTextField, NevsCard},
    data() {
        return {
            loaded: false,
            mode: this.$route.params.id === '0' ? 'add' : 'edit',
            user: new User({id: this.$route.params.id}),
            userValidation: new User({}, true),
            changePassword: false
        }
    },
    methods: {
        backClick() {
            if (window.history.length === 1) {
                window.close();
            } else {
                this.$router.back();
            }
        },
        saveClick() {
            let valid = true;

            if (this.user.first_name === '') {
                valid = false;
                this.userValidation.first_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.userValidation.first_name = '';
            }

            if (this.user.last_name === '') {
                valid = false;
                this.userValidation.last_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.userValidation.last_name = '';
            }

            if (this.user.email === '') {
                valid = false;
                this.userValidation.email = this.$LANG.Get('labels.mandatoryField');
            } else {
                if (!this.$HELPERS.CheckEmail(this.user.email)) {
                    valid = false;
                    this.userValidation.email = this.$LANG.Get('labels.invalidEmailAddress');
                } else {
                    this.userValidation.email = '';
                }
            }

            if (this.mode === 'edit' && !this.changePassword) {
                this.user.password = '';
            }

            if (this.mode === 'add' || this.changePassword) {
                if (this.user.password === '') {
                    valid = false;
                    this.userValidation.password = this.$LANG.Get('labels.mandatoryField');
                } else {
                    this.userValidation.password = '';
                }
            }

            if (this.user.oib !== '') {
                if (!this.$HELPERS.CheckOIB(this.user.oib)) {
                    valid = false;
                    this.userValidation.oib = this.$LANG.Get('labels.invalidOIB');
                } else {
                    this.userValidation.oib = '';
                }
            } else {
                if (this.user.permissions.includes('FISKAL')) {
                    valid = false;
                    this.userValidation.oib = this.$LANG.Get('labels.fiskalOIBfail');
                } else {
                    this.userValidation.oib = '';
                }
            }

            if (valid) {
                let vm = this;
                let requestMethod = this.mode === 'add' ? 'post' : 'put';
                let requestService = this.mode === 'add' ? 'users' : 'users/' + this.user.id;
                this.$API.APICall(requestMethod, requestService, this.user, (data, success) => {
                    if (success) {
                        vm.$CROSS_TAB_BUS.TriggerEvent('reload-users', {});
                        vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        vm.$API.APICall('get', 'session', {}, (data, success) => {
                            if (success) {
                                vm.$store.commit('setUser', data.user);
                                vm.backClick();
                            }
                        }, false);
                    } else {
                        if (data.error === 'email_duplicate') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.emailDuplicate'),
                                type: 'alert'
                            });
                        } else {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.serverError'),
                                type: 'alert'
                            });
                        }
                    }
                })
            }
        }
    },
    mounted() {
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-save',
                html: this.$LANG.Get('help.save'),
                left: -100,
                top: 0,
                width: 150
            },
            {
                class: 'help-back',
                html: this.$LANG.Get('help.back'),
                left: 85,
                top: -30,
                width: 300
            },
            {
                class: 'help-password',
                html: this.$LANG.Get('help.password'),
                left: 85,
                top: -30,
                width: 300
            }
        ]);

        this.$store.commit('selectMenu', 'settings');
        this.$store.commit('selectSubMenu', 'users');

        if (this.mode === 'add') {
            if (!this.$store.state.user.permissions.includes('ADMINISTRATOR')) {
                this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
                this.backClick();
            }
            this.$store.commit('setBreadcrumbs', [
                {
                    label: this.$LANG.Get('modules.settings'),
                    link: null
                },
                {
                    label: this.$LANG.Get('modules.users'),
                    link: '/users'
                },
                {
                    label: this.$LANG.Get('modules.newUser'),
                    link: null
                }
            ]);
            this.loaded = true;
        } else {
            let vm = this;
            this.$API.APICall('get', 'users/' + this.user.id, {}, (data, success) => {
                if (success) {
                    if (data.user !== null) {
                        vm.user.Fill(data.user);
                        vm.$store.commit('setBreadcrumbs', [
                            {
                                label: this.$LANG.Get('modules.settings'),
                                link: null
                            },
                            {
                                label: vm.$LANG.Get('modules.users'),
                                link: '/users'
                            },
                            {
                                label: vm.user.first_name + ' ' + vm.user.last_name,
                                link: null
                            }
                        ]);
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
                vm.$nextTick(() => {
                    vm.loaded = true;
                });
            });
        }
    }
}
</script>

<style scoped>
.form {
    max-width: 300px;
}
</style>