<template>
    <div class="help-content">
        <h1>Postavljanje fiskalizacije</h1>
        <p>
            Za postavljanje fiskalizacije potrebno je napraviti nekoliko koraka.<br/>
            <ol>
                <li>Postaviti certifikat i njegovu lozinku u financijskim postavkama.</li>
                <li>Postaviti slijed za fiskalizaciju u financijskim postavkama.</li>
                <li>Korisnicima unijeti OIB i dodijeliti prava za fiskaliziranje.</li>
            </ol>
        </p>
        <h2>Postavljanje certifikata, lozinke i slijeda u financijskim postavkama</h2>
        <p>
            Iz lijevog izbornika je potrebno odabrati <strong>Postavke > Financije</strong>.<br/>
            U polje <strong>Certifikat za fiskalizaciju</strong> je potrebno uploadati datoteku certifikata.<br/>
            U polje <strong>Lozinka certifikata</strong> je potrebno upisati lozinku certifikata.<br/>
            U tablici <strong>Slijedovi brojeva računa</strong> je potrebno postaviti kvačicu u stupcu
            <strong>Fisk.</strong> za barem jedan slijed.<br/>
            Na kraju je potrebno kliknutu gumb <strong>Spremi</strong> koji se nalazi na vrhu.
        </p>
        <h2>Dodjeljivanje prava korisnicima</h2>
        <p>
            Iz lijevog izbornika je potrebno odabrati <strong>Postavke > Korisnici</strong>, te otvoriti korisnika kojem
            želimo dodati prava za fiskalizaciju.<br/>
            Potrebno je unijeti korisnikov OIB u polje <strong>OIB</strong>, te u polju <strong>Dopuštenja</strong>
            odabrati <i>Fiskalizacija</i>.<br/>
            Na kraju je potrebno kliknutu gumb <strong>Spremi</strong> koji se nalazi na vrhu.<br/>
            Postupak je potrebno ponoviti za sve korisnike koj smiju fiskalizirati račune.
        </p>
        <h2>Fiskaliziranje računa</h2>
        <p>
            Kod izdavanja računa je u polju <strong>Slijed</strong> potrebno odabrati slijed koji se fiskalizira.<br/>
            Ako imate postavljen samo jedan slijed onda se polje <strong>Slijed</strong> ne vidi i slijed je
            odabran.<br/>
            Takav račun će se automatski pokušati fiskalizirati nakon izdavanja.<br/>
            Ako je fiskalizacija uspjela u tablici s računima će u retku tog računa u stupcu
            <strong>Fiskalizacija</strong> biti zelena ikona.<br/>
            Ako je došlo do greške prilikom fiskalizacije ta ikona će biti crvena.<br/>
            Klikom na crvenu ikonu možete probati ponovno fiskalizirati taj račun.
        </p>
        <div class="disclaimer">
            Računi koji su fiskalizirani se ne mogu brisati i mijenjati, ali se mogu stornirati.<br/>
            Predlažemo da se oko postavki fiskalizacije i slijedova posavjetujete sa svojim računovodstvom.
        </div>
    </div>
</template>

<script>

export default {
    name: "HelpFiskal",
    components: {},
    data() {
        return {}
    },
}
</script>

<style scoped>
.disclaimer {
    color: red;
    font-weight: bold;
}

.help-content {
    background: white;
}

.help-content a {
    font-weight: bold;
}
</style>