<template>
    <div v-if="loaded" class="nevs-content">
        <div v-if="paymentPopup.show" class="nevs-popup-container">
            <div class="nevs-popup">
                <div class="nevs-popup-text">
                    <NevsNumberField v-model="paymentPopup.data.amount"
                                     :label="$LANG.Get('fields.amount') + ' (' + paymentCurrency + ') :'"></NevsNumberField>
                    <NevsDateField v-model="paymentPopup.data.date" :error="paymentPopup.validation.date"
                                   :label="$LANG.Get('fields.date') + ':'"></NevsDateField>
                </div>
                <div class="nevs-popup-buttons">
                    <NevsButton class="error" @click="paymentPopup.cancel()">{{
                            $LANG.Get('buttons.cancel')
                        }}
                    </NevsButton>
                    <NevsButton class="success" @click="paymentPopup.save()">{{ $LANG.Get('buttons.ok') }}</NevsButton>
                </div>
            </div>
        </div>
        <div class="nevs-content-top-buttons">
            <NevsButton class="success help-save" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
            <NevsButton class="error help-back" @click="backClick"><i class="fa-solid fa-arrow-left"></i>
                {{ $LANG.Get('buttons.back') }}
            </NevsButton>
        </div>
        <div class="tabs">
            <NevsButton :class="{'active' : currentTab === 1}" @click="currentTab = 1">
                {{ $LANG.Get('buttons.generalData') }}
            </NevsButton>
            <NevsButton :class="{'active' : currentTab === 2}" @click="currentTab = 2">
                {{ $LANG.Get('buttons.payments') }}
            </NevsButton>
        </div>
        <div v-if="currentTab === 1" class="tab-body">
            <NevsCard class="form form-float">
                <NevsSelect v-model="invoice.customer_id" :ajax="'select/customers'"
                            :error="invoiceValidation.customer_id"
                            :label="$LANG.Get('fields.customer')"
                            :readonly="readonly"></NevsSelect>
                <div v-if="this.selectedCustomer !== null" class="customer-details">
                    {{ this.selectedCustomer.address }}<br/>
                    {{ this.selectedCustomer.postal_code }} {{ this.selectedCustomer.city }}<br/>
                    {{ this.selectedCustomer.country }}<br/>
                </div>
                <div v-if="showFiskalNotice && mode !== 'edit'" class="fiskal-notice nevs-text-error">
                    {{ $LANG.Get('labels.fiskalNotice') }}
                </div>
            </NevsCard>
            <NevsCard class="form form-float help-dates">
                <NevsDateField v-model="timeOfIssue.date" :error="timeOfIssueValidation.date"
                               :label="$LANG.Get('fields.dateOfIssue')"
                               :readonly="readonly"></NevsDateField>
                <NevsMaskedField v-model="timeOfIssue.time" :error="timeOfIssueValidation.time"
                                 :label="$LANG.Get('fields.timeOfIssue')"
                                 :mask="'NN:NN:NN'"
                                 :readonly="readonly"></NevsMaskedField>
                <NevsDateField v-model="invoice.due_date" :error="invoiceValidation.due_date"
                               :label="$LANG.Get('fields.dueDate')"
                               :readonly="readonly"></NevsDateField>
                <NevsDateField v-model="invoice.date_of_delivery" :error="invoiceValidation.date_of_delivery"
                               :label="$LANG.Get('fields.dateOfDelivery')"
                               :readonly="readonly"></NevsDateField>
            </NevsCard>
            <NevsCard class="form form-float">
                <NevsSelect v-if="showCurrency" v-model="invoice.currency" :ajax="currencyEndpoint"
                            :error="invoiceValidation.currency"
                            :label="$LANG.Get('fields.currency')"
                            :readonly="readonly || currencyReadonly"></NevsSelect>
                <NevsSelect :readonly="cancelling || invoice.cancels !== 0 || invoice.cancelled_by !== 0"
                            v-if="mode !== 'add' && mode !== 'copy'" v-model="invoice.invoice_status_id"
                            :ajax="'select/invoice-statuses'"
                            :error="invoiceValidation.invoice_status_id" :label="$LANG.Get('fields.status')"></NevsSelect>
                <NevsSelect v-if="(mode !== 'edit') && sequences.length > 1"
                            v-model="invoice.invoice_sequence_id"
                            :error="invoiceValidation.invoice_sequence_id"
                            :label="$LANG.Get('fields.sequence')" :options="sequences"
                            :readonly="readonly || cancelling"></NevsSelect>
                <NevsSelect v-model="invoice.locale" :ajax="'select/languages'"
                            :error="invoiceValidation.locale" :label="$LANG.Get('fields.language')"
                            :readonly="readonly"></NevsSelect>
                <NevsSelect v-model="invoice.payment_method_id" :ajax="'select/payment-methods'"
                            :error="invoiceValidation.payment_method_id" :label="$LANG.Get('fields.paymentMethod')"
                            :readonly="readonly"></NevsSelect>
            </NevsCard>
            <NevsCard class="items-card">
                <NevsSelect v-if="!readonly" v-model="selectedProduct" :ajax="'select/products'"
                            :error="invoiceValidation.items"
                            :label="$LANG.Get('labels.selectItem')" class="item-select help-item"></NevsSelect>
                <div class="items-container">
                    <table v-show="invoice.items.length !== 0" class="items-table">
                        <tr class="items-table-header">
                            <td v-for="(header, key) in headers" :key="key" :style="{'width': header.width}">
                                {{ header.text }}
                            </td>
                        </tr>
                        <tr v-for="(item, key) in invoice.items" :key="key">
                            <td class="items-table-buttons">
                                <span v-show="!readonly" @click="removeItemClick(key)"><i class="fa-solid fa-trash"></i></span>
                            </td>
                            <td>
                                <NevsTextField v-model="item.description" :readonly="readonly"></NevsTextField>
                            </td>
                            <td>
                                <NevsSelect v-model="item.measurement_unit_id" :ajax="'select/measurement-units'"
                                            :readonly="readonly"></NevsSelect>
                            </td>
                            <td>
                                <NevsNumberField v-model="item.price" :readonly="readonly"></NevsNumberField>
                            </td>
                            <td>
                                <NevsNumberField v-model="item.quantity" :decimal-places="3" :readonly="readonly"
                                                 :thousand-separator="''"></NevsNumberField>
                            </td>
                            <td v-if="invoice.tax_system && !invoice.ppo">
                                <NevsNumberField v-model="item.tax" :readonly="readonly"></NevsNumberField>
                            </td>
                            <td>
                                <NevsNumberField v-model="item.discount" :readonly="readonly"></NevsNumberField>
                            </td>
                            <td>
                                <NevsNumberField v-model="item.total" :readonly="true"></NevsNumberField>
                            </td>
                        </tr>
                    </table>
                    <table v-show="invoice.items.length !== 0" class="summary-table">
                        <tr>
                            <td class="sub-table-left">{{ $LANG.Get('labels.additionalDiscount') }} (%):</td>
                            <td class="sub-table-right">
                                <NevsNumberField v-model="invoice.discount" :readonly="readonly"
                                                 :thousand-separator="''"></NevsNumberField>
                            </td>
                        </tr>
                        <tr v-for="(base, key) in taxBases" :key="key">
                            <td>
                                {{ $LANG.Get('labels.taxBase') }} {{ base.base }} ({{ invoice.currency }}):
                            </td>
                            <td>
                                <NevsNumberField v-model="base.value" :readonly="true"></NevsNumberField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ $LANG.Get('fields.total') }} ({{ invoice.currency }}):
                            </td>
                            <td>
                                <NevsNumberField v-model="total" :readonly="true"></NevsNumberField>
                            </td>
                        </tr>
                    </table>
                    <div class="nevs-clear-float"></div>
                </div>
            </NevsCard>
            <NevsCard class="footer-container">
                <NevsTextArea v-model="invoice.footer" :error="invoiceValidation.footer"
                              :label="$LANG.Get('fields.footer')"
                              :readonly="readonly"></NevsTextArea>
                <NevsCheckbox class="help-ppo" v-model="invoice.ppo" :label="$LANG.Get('fields.ppo')"
                              :readonly="readonly"></NevsCheckbox>
                <NevsTextField v-if="invoice.ppo" v-model="invoice.ppo_text"
                               :label="$LANG.Get('fields.ppoText')" :readonly="readonly"></NevsTextField>
                <NevsCheckbox class="help-advance" v-model="invoice.paid_by_advance" :label="$LANG.Get('labels.paidByAdvance')"
                              :readonly="readonly"></NevsCheckbox>
                <NevsTextField v-if="invoice.paid_by_advance" v-model="invoice.advance_text"
                               :label="$LANG.Get('fields.advanceText')" :readonly="readonly"></NevsTextField>
                <NevsNumberField v-if="invoice.paid_by_advance" v-model="invoice.advance_amount"
                                 :label="$LANG.Get('fields.advanceAmount')"
                                 :readonly="readonly" :width="'200px'"></NevsNumberField>
                <NevsSelect class="bank-field" v-if="ibanOptions.length > 1" :label="$LANG.Get('fields.iban')"
                            v-model="invoice.iban" :options="ibanOptions"></NevsSelect>
                <NevsSelect class="bank-field" v-if="swiftOptions.length > 1" :label="$LANG.Get('fields.swift')"
                            v-model="invoice.swift" :options="swiftOptions"></NevsSelect>
            </NevsCard>
        </div>
        <div v-if="currentTab === 2" class="tab-body">
            <NevsCard class="payments">
                <div v-if="invoice.payments.length === 0" class="payments-message">
                    {{ $LANG.Get('labels.noPayments') }}
                </div>
                <div v-if="invoice.payments.length > 0" class="payments-list">
                    <div v-for="(payment, key) in invoice.payments" :key="key" class="payment">
                        <table>
                            <tr>
                                <td v-if="invoice.payment_method_id === 1" class="payment-buttons" rowspan="2">
                                    <span class="payment-button" @click="deletePayment(key)"><i
                                        class="fa-solid fa-trash"></i></span>&nbsp;
                                    <span class="payment-button" @click="editPayment(key)"><i
                                        class="fa-solid fa-pen"></i></span>
                                </td>
                                <td class="payment-amount">{{ $HELPERS.FormatMoney(payment.amount) }}
                                    {{ payment.currency }}
                                </td>
                            </tr>
                            <tr>
                                <td class="payment-date">{{ $HELPERS.FormatDate(payment.date) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <NevsButton v-if="invoice.payment_method_id === 1" @click="addPayment">
                    {{ $LANG.Get('buttons.add') }}
                </NevsButton>
            </NevsCard>
        </div>
    </div>
</template>

<script>
import Invoice from '@/models/Invoice';
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import Big from 'big.js';
import NevsSelect from "@/components/nevs/NevsSelect";
import NevsDateField from "@/components/nevs/NevsDateField";
import NevsMaskedField from "@/components/nevs/NevsMaskedField";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsNumberField from "@/components/nevs/NevsNumberField";
import NevsTextArea from "@/components/nevs/NevsTextArea";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";
import moment from "moment";

export default {
    name: "EntityInvoice",
    components: {
        NevsCheckbox,
        NevsTextArea,
        NevsNumberField, NevsTextField, NevsMaskedField, NevsDateField, NevsSelect, NevsButton, NevsCard
    },
    data() {
        return {
            loaded: false,
            cancelling: false,
            mode: this.$route.params.mode,
            invoice: new Invoice({
                id: this.$route.params.id,
                iban: this.$store.state.user.client.iban[0],
                swift: this.$store.state.user.client.swift[0]
            }),
            invoiceValidation: new Invoice({}, true),
            selectedCustomer: null,
            selectedProduct: null,
            taxBases: [],
            tempTaxBases: [],
            total: 0,
            currencyReadonly: false,
            originalCustomerId: null,
            newCustomerData: null,
            customerChanged: false,
            timeOfIssue: {
                date: null,
                time: null
            },
            timeOfIssueValidation: {
                date: '',
                time: ''
            },
            sequences: [],
            readonly: false,
            offerNumber: '',
            workOrderNumber: '',
            currentTab: 1,
            paymentPopup: {
                show: false,
                indexToEdit: null,
                data: {
                    date: moment().format('YYYY-MM-DD'),
                    amount: 0
                },
                validation: {
                    date: ''
                },
                reset: () => {
                    this.paymentPopup.data = {
                        date: moment().format('YYYY-MM-DD'),
                        amount: this.invoice.financial_status
                    };
                    this.paymentPopup.validation = {
                        name: ''
                    }
                    this.paymentPopup.indexToEdit = null;
                },
                cancel: () => {
                    this.paymentPopup.show = false;
                },
                save: () => {
                    let success = true;
                    if (this.paymentPopup.data.date === null) {
                        success = false;
                        this.paymentPopup.validation.date = this.$LANG.Get('labels.mandatoryField');
                    } else {
                        this.paymentPopup.validation.date = '';
                    }
                    if (success) {
                        if (this.paymentPopup.indexToEdit === null) {
                            this.invoice.payments.push({
                                amount: this.paymentPopup.data.amount,
                                date: this.paymentPopup.data.date,
                                currency: this.paymentCurrency
                            });
                        } else {
                            this.invoice.payments[this.paymentPopup.indexToEdit] = {
                                amount: this.paymentPopup.data.amount,
                                date: this.paymentPopup.data.date,
                                currency: this.paymentCurrency
                            };
                        }

                        this.paymentPopup.show = false;
                        this.paymentPopup.reset();
                    }
                }
            },
            showFiskalNotice: false,
            showCurrency: false,
            currencyEndpoint: 'select/currencies',
            originalStatus: null
        }
    },
    computed: {
        paymentCurrency() {
            if (this.invoice.currency === 'HRK' && this.paymentPopup.data.date + ' 00:00:00' >= this.$store.state.settings.EUR_TRANSITION) {
                return 'EUR';
            }
            return this.invoice.currency;
        },
        headers() {
            let headers = [
                {
                    text: '',
                    sortable: false,
                    value: 'actions',
                    width: '50px',
                    class: 'pa-0'
                },
                {
                    text: this.$LANG.Get('fields.description'),
                    sortable: false,
                    value: 'description',
                    class: 'pa-0 description-width'
                },
                {
                    text: this.$LANG.Get('fields.measurementUnit'),
                    sortable: false,
                    value: 'measurement_unit',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.price') + ' (' + this.invoice.currency + ')',
                    sortable: false,
                    value: 'price',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.quantity'),
                    sortable: false,
                    value: 'quantity',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.discount'),
                    sortable: false,
                    value: 'discount',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.total') + ' (' + this.invoice.currency + ')',
                    sortable: false,
                    value: 'total',
                    class: 'pa-0',
                    width: "100px"
                }
            ]
            if (this.invoice.tax_system && !this.invoice.ppo) {
                headers.splice(5, 0, {
                    text: this.$LANG.Get('fields.tax'),
                    sortable: false,
                    value: 'tax',
                    class: 'pa-0',
                    width: "100px"
                })
            }
            return headers;
        },
        ibanOptions() {
            let options = [];
            let current = this.invoice.iban;
            let currentExists = false;
            for (let iban of this.$store.state.user.client.iban) {
                if (iban === current) currentExists = true;
                options.push({
                    label: iban,
                    value: iban
                });
            }
            if (!currentExists) {
                options.push({
                    label: current,
                    value: current
                });
            }
            return options;
        },
        swiftOptions() {
            let options = [];
            let current = this.invoice.swift;
            let currentExists = false;
            for (let swift of this.$store.state.user.client.swift) {
                if (swift === current) currentExists = true;
                options.push({
                    label: swift,
                    value: swift
                });
            }
            if (!currentExists) {
                options.push({
                    label: current,
                    value: current
                });
            }
            return options;
        }
    },
    watch: {
        'invoice.locale': function () {
            if (this.loaded) {
                let vm = this;
                for (let i = 0; i < this.invoice.items.length; i++) {
                    if (this.invoice.items[i].product_id !== null) {
                        this.$API.APICall('get', 'products/' + this.invoice.items[i].product_id, {}, (data, success) => {
                            if (success) {
                                vm.invoice.items[i].description = (data.product['name_' + vm.invoice.locale] !== undefined) ? data.product['name_' + vm.invoice.locale] : data.product.name;
                            }
                        }, false);
                    }
                }
                this.invoice.footer = this.$store.state.user.client['invoice_footer_template_' + this.invoice.locale];
                this.invoice.ppo_text = this.invoice.ppo ? this.$store.state.user.client['ppo_template_' + this.invoice.locale] : '';
            }
        },
        'invoice.customer_id': function () {
            if (this.invoice.customer_id != null) {
                let vm = this;
                this.$API.APICall('get', 'customers/' + this.invoice.customer_id, {}, (data, success) => {
                    if (success) {
                        vm.selectedCustomer = data.customer;
                    }
                });
            } else {
                this.selectedCustomer = null;
            }
        },
        'selectedProduct': function () {
            if (this.selectedProduct != null) {
                this.addItem();
            }
        },
        'invoice.items': {
            handler: function () {
                this.calculateTotals();
            },
            deep: true
        },
        'invoice.ppo': {
            handler: function () {
                this.calculateTotals();
                if (this.loaded) {
                    this.invoice.ppo_text = this.invoice.ppo ? this.$store.state.user.client['ppo_template_' + this.invoice.locale] : '';
                }
            },
            deep: true
        },
        'invoice.discount': {
            handler: function () {
                this.calculateTotals();
            },
            deep: true
        },
        'invoice.currency': {
            handler: function () {
                this.loadCurrencyRate();
            },
            deep: true
        },
        'invoice.invoice_sequence_id': {
            handler: function () {
                let vm = this;
                this.$API.APICall('get', 'invoice-sequences/' + this.invoice.invoice_sequence_id, {}, (data, success) => {
                    vm.showFiskalNotice = (success && data.invoice_sequence !== null && data.invoice_sequence.fiskal);
                }, false);
            },
            deep: true
        },
        'timeOfIssue': {
            handler: function () {
                this.showCurrency = false;
                this.currencyEndpoint = 'select/currencies';
                if (this.timeOfIssue.date + ' ' + this.timeOfIssue.time < this.$store.state.settings.EUR_TRANSITION) {
                    this.currencyEndpoint = 'select/currencies-hrk';
                }
                this.$nextTick(() => {
                    this.invoice.time_of_issue = this.timeOfIssue.date + ' ' + this.timeOfIssue.time;
                    this.showCurrency = true;
                });
            },
            deep: true
        },
        'invoice.time_of_issue': {
            handler: function () {
                this.timeOfIssue.date = this.invoice.time_of_issue.split(' ')[0];
                this.timeOfIssue.time = this.invoice.time_of_issue.split(' ')[1];
                let moment = require('moment');
                if (this.invoice.time_of_issue > moment().format('YYYY-MM-DD') + ' 23:59:59') {
                    this.invoice.currency = this.$HELPERS.GetDefaultCurrency();
                    this.currencyReadonly = true;
                } else {
                    this.currencyReadonly = false;
                }
                this.loadCurrencyRate();
            },
            deep: true
        },
        'invoice.paid_by_advance': {
            handler: function () {
                if (this.loaded) {
                    this.invoice.advance_text = this.invoice.paid_by_advance ? this.$LANG.Get('texts.advanceText_' + this.invoice.locale) : '';
                    this.invoice.advance_amount = 0;
                }
            },
            deep: true
        }
    },
    methods: {
        editPayment(index) {
            this.paymentPopup.indexToEdit = index;
            this.paymentPopup.data.amount = this.invoice.payments[index].amount;
            this.paymentPopup.data.date = this.invoice.payments[index].date;
            this.paymentPopup.show = true;
        },
        deletePayment(index) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
                    if (response) {
                        vm.invoice.payments.splice(index, 1);
                    }
                }
            });
        },
        addPayment() {
            this.paymentPopup.reset();
            this.paymentPopup.show = true;
        },
        loadCurrencyRate() {
            let vm = this;
            let oldRate = this.invoice.currency_rate;
            let loaded = this.loaded;
            this.$API.APICall('get', 'currencies/' + this.invoice.currency, {date: this.invoice.time_of_issue}, (data, success) => {
                if (success) {
                    if (data.rate != null) {
                        vm.invoice.currency_rate = data.rate;
                        if (loaded) {
                            for (let index in vm.invoice.items) {
                                let item = vm.invoice.items[index];
                                let price = Big(item.price);
                                vm.invoice.items[index]['price'] = ((price * oldRate).toFixed(2) / Big(data.rate)).toFixed(2);
                            }
                        }
                    }
                }
            }, false);
        },
        calculateTotals() {
            this.tempTaxBases = [];
            this.total = Big(0);
            for (let index in this.invoice.items) {
                let item = this.invoice.items[index];
                let total = Big(item.price).times(Big(item.quantity));
                total = Big(total.toFixed(2));
                let item_discount = total.times(Big(item.discount)).div(Big(100));
                item_discount = item_discount.toFixed(2);
                total = total.minus(item_discount);
                total = Big(total.toFixed(2));
                let total_with_discount = total;
                let discount_amount = total_with_discount.times(Big(this.invoice.discount)).div(Big(100));
                discount_amount = Big(discount_amount.toFixed(2));
                total_with_discount = total_with_discount.minus(discount_amount);
                if (this.invoice.tax_system && !this.invoice.ppo) {
                    this.addToTempBase(item.tax, total_with_discount);
                }
                this.invoice.items[index]['total'] = total.toFixed(2);
                this.total = this.total.add(total_with_discount.toFixed(2));
            }
            this.taxBases = JSON.parse(JSON.stringify(this.tempTaxBases));
            if (this.invoice.tax_system && !this.invoice.ppo) {
                this.total = Big(0);
                for (let index in this.taxBases) {
                    let currentBase = this.taxBases[index];
                    let currentTaxAmount = (Big(currentBase.value).times(Big(currentBase.base)).div(Big(100))).toFixed(2);
                    this.total = this.total.add(currentTaxAmount).add(Big(currentBase.value));
                }
            }
        },
        addToTempBase(base, value) {
            let term = false;
            for (let index in this.tempTaxBases) {
                let currentBase = this.tempTaxBases[index];
                if (currentBase.base === base) {
                    term = true;
                    currentBase.value = Big(currentBase.value).add(Big(value)).toString();
                }
            }
            if (!term) {
                this.tempTaxBases.push({
                    base: base,
                    value: Big(value).toString()
                });
            }
        },
        backClick() {
            if (window.history.length === 1) {
                window.close();
            } else {
                this.$router.back();
            }
        },
        saveClick() {
            if (this.invoice.customer_id !== this.originalCustomerId) {
                this.saveAction();
            } else {
                if (!this.customerChanged) {
                    this.saveAction();
                } else {
                    let vm = this;
                    this.$LOCAL_BUS.TriggerEvent('popup', {
                        type: 'confirm',
                        text: this.$LANG.Get('alerts.customerChangesQuestionInvoice'),
                        callback: (response) => {
                            if (response) {
                                vm.invoice.customer_data = JSON.parse(JSON.stringify(vm.newCustomerData));
                                vm.customerChanged = false;
                            }
                            vm.saveAction();
                        }
                    });
                }
            }
        },
        saveAction() {
            let success = true;

            if (this.invoice.customer_id == null) {
                success = false;
                this.invoiceValidation.customer_id = this.$LANG.Get('labels.customerIdMissing');
            } else {
                this.invoiceValidation.customer_id = '';
            }

            if (this.originalStatus != null && this.originalStatus < 4 && this.invoice.invoice_status_id >= 4) {
                success = false;
                this.invoiceValidation.invoice_status_id = this.$LANG.Get('labels.statusNotAllowed');
            } else {
                this.invoiceValidation.invoice_status_id = '';
            }

            if (this.invoice.due_date == null) {
                success = false;
                this.invoiceValidation.due_date = this.$LANG.Get('labels.dueDateMissing');
            } else {
                this.invoiceValidation.due_date = '';
            }

            if ((this.invoice.time_of_issue == null) || (!this.$HELPERS.DateTimePattern().test(this.invoice.time_of_issue))) {
                success = false;
                this.timeOfIssueValidation.time = this.$LANG.Get('labels.timeOfIssueMissing');
            } else {
                this.timeOfIssueValidation.time = '';
            }

            let itemsValidation = '';
            if (this.invoice.items.length === 0) {
                success = false;
                itemsValidation = this.$LANG.Get('labels.noItems');
            } else {
                itemsValidation = '';
            }
            let term = false;
            for (let item of this.invoice.items) {
                if (item.description === '') {
                    term = true;
                }
            }
            if (term) {
                if (itemsValidation !== '') {
                    itemsValidation += '\n';
                }
                success = false;
                itemsValidation += this.$LANG.Get('labels.emptyItem');
            }
            this.invoiceValidation.items = itemsValidation;

            if (this.invoice.payment_method_id > 1 && !this.showFiskalNotice) {
                success = false;
                this.invoiceValidation.payment_method_id = this.$LANG.Get('labels.fiskalNeeded');
            } else {
                this.invoiceValidation.payment_method_id = '';
            }

            if (success) {
                let data = JSON.parse(JSON.stringify(this.invoice));
                let vm = this;
                let action = 'post';
                let addition = '';
                if (this.mode === 'edit') {
                    action = 'put';
                    addition = '/' + this.invoice.id;
                }
                this.$API.APICall(action, 'invoices' + addition, data, (data, success) => {
                    if (success) {
                        vm.$CROSS_TAB_BUS.TriggerEvent('reload-invoices', {});
                        if (vm.mode === 'add' || vm.mode === 'cancel' || vm.mode === 'copy' || vm.mode === 'offer' || vm.mode === 'work-order') {
                            vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                            if (data.invoice.zki !== '' && vm.mode !== 'edit') {
                                vm.$API.APICall('POST', 'invoices/fiskal/' + data.invoice.id, {}, (data, success) => {
                                    if (!success) {
                                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                                            text: vm.$LANG.Get('alerts.fiskalError'),
                                            type: 'alert'
                                        });
                                    }
                                    vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                                    vm.backClick();
                                });
                            } else {
                                vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                                vm.backClick();
                            }
                        } else {
                            vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        }
                    } else {
                        if (data.error === 'invalid sequence later') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.invoiceSequenceLater'),
                                type: 'alert'
                            });
                        } else if (data.error === 'invalid sequence earlier') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.invoiceSequenceEarlier'),
                                type: 'alert'
                            });
                        } else if (data.error === 'invalid year') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.invoiceInvalidYear'),
                                type: 'alert'
                            });
                        } else {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.serverError'),
                                type: 'alert'
                            });
                        }
                    }
                });
            }
        },
        addItem() {
            if (this.selectedProduct === null) return;
            if (this.selectedProduct === -1) {
                let newItem = {
                    description: '',
                    price: 0,
                    quantity: 1,
                    tax: this.$store.state.settings.DEFAULT_TAX,
                    discount: 0,
                    measurement_unit_id: (this.$store.state.user.client.default_measurement_unit_id !== null) ? this.$store.state.user.client.default_measurement_unit_id : 1,
                    total: 0,
                    product_id: null
                }
                this.invoice.items.push(newItem);
                this.$nextTick(() => {
                    this.selectedProduct = null;
                });
            } else {
                let vm = this;
                this.$API.APICall('get', 'products/' + this.selectedProduct, {}, (data, success) => {
                    if (success) {
                        let newItem = {
                            description: (data.product['name_' + vm.invoice.locale] !== undefined) ? data.product['name_' + vm.invoice.locale] : data.product.name,
                            price: data.product.price * this.invoice.currency_rate,
                            quantity: 1,
                            tax: data.product.tax,
                            discount: 0,
                            measurement_unit_id: data.product.measurement_unit_id,
                            total: 0,
                            product_id: data.product.id
                        }
                        if (this.invoice.time_of_issue < this.$store.state.settings.EUR_TRANSITION) {
                            newItem.price = data.product.price / this.invoice.currency_rate;
                        }
                        vm.invoice.items.push(newItem);
                        vm.selectedProduct = null;
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                }, false);
            }
        },
        removeItemClick(index) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
                    if (response) {
                        vm.invoice.items.splice(index, 1);
                    }
                }
            });
        }
    },
    mounted() {
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-save',
                html: this.$LANG.Get('help.save'),
                left: -100,
                top: 0,
                width: 150
            },
            {
                class: 'help-back',
                html: this.$LANG.Get('help.back'),
                left: 85,
                top: -30,
                width: 300
            },
            {
                class: 'help-dates',
                html: this.$LANG.Get('help.dates'),
                left: 0,
                top: 0,
                width: 300
            },
            {
                class: 'help-item',
                html: this.$LANG.Get('help.item'),
                left: 300,
                top: -30,
                width: 300
            },
            {
                class: 'help-ppo',
                html: this.$LANG.Get('help.ppo'),
                left: 300,
                top: -60,
                width: 500
            },
            {
                class: 'help-advance',
                html: this.$LANG.Get('help.advance'),
                left: 300,
                top: -40,
                width: 500
            }
        ]);

        this.$store.commit('selectMenu', 'invoices');
        this.$store.commit('selectSubMenu', null);

        this.sequences = this.$store.state.user.invoice_sequences;
        if (this.sequences.length > 0) {
            this.invoice.invoice_sequence_id = this.$store.state.user.default_invoice_sequence_id;
        } else {
            this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
            this.$router.push('/');
        }

        if (this.mode === 'add') {
            this.$store.commit('setBreadcrumbs', [
                {
                    label: this.$LANG.Get('modules.invoices'),
                    link: '/invoices'
                },
                {
                    label: this.$LANG.Get('modules.newInvoice'),
                    link: null
                }
            ]);

            this.invoice.customer_id = this.$store.state.user.client.invoice_default_customer_id;
            this.invoice.tax_system = this.$store.state.user.client.tax_system;
            this.invoice.time_of_issue = moment().format('YYYY-MM-DD HH:mm:ss');
            this.invoice.date_of_delivery = moment().format('YYYY-MM-DD');
            this.invoice.due_date = moment().add(this.$store.state.user.client.invoice_due_days, 'days').format('YYYY-MM-DD');
            this.invoice.footer = this.$store.state.user.client['invoice_footer_template_' + this.$store.state.locale];
            this.invoice.locale = this.$store.state.locale;
            this.invoice.payment_method_id = 1;

            this.$nextTick(() => {
                this.loaded = true;
            });
        } else if (this.mode === 'edit') {
            let vm = this;
            this.$API.APICall('get', 'invoices/' + this.invoice.id, {}, (data, success) => {
                if (success) {
                    if (data.invoice !== null) {
                        if ((data.invoice.e_invoice_id != null) || (data.invoice.cancels !== 0) || (data.invoice.cancelled_by !== 0) || (data.invoice.zki !== '')) {
                            vm.readonly = true;
                        }
                        vm.invoice.Fill(data.invoice);
                        vm.originalStatus = data.invoice.invoice_status_id;
                        vm.customerChanged = data.invoice.customer_changed;
                        vm.originalCustomerId = data.invoice.customer_id;
                        vm.newCustomerData = data.invoice.new_customer_data;
                        let breadcrumb = vm.invoice.number;
                        if (vm.invoice.e_invoice_id != null) breadcrumb += ' (E)';
                        vm.$store.commit('setBreadcrumbs', [
                            {
                                label: vm.$LANG.Get('modules.invoices'),
                                link: '/invoices'
                            },
                            {
                                label: breadcrumb,
                                link: null
                            }
                        ]);
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
                vm.$nextTick(() => {
                    vm.loaded = true;
                });
            });
        } else if (this.mode === 'copy') {
            let vm = this;
            this.$API.APICall('get', 'invoices/' + this.invoice.id, {}, (data, success) => {
                if (success) {
                    vm.invoice.id = 0;
                    vm.invoice.time_of_issue = moment().format('YYYY-MM-DD HH:mm:ss');
                    vm.invoice.due_date = moment().add(this.$store.state.user.client.invoice_valid_days, 'days').format('YYYY-MM-DD');
                    vm.invoice.date_of_delivery = moment().format('YYYY-MM-DD');
                    vm.invoice.customer_id = data.invoice.customer_id;
                    vm.invoice.currency = data.invoice.currency;
                    if (vm.invoice.time_of_issue >= this.$store.state.settings.EUR_TRANSITION && vm.invoice.currency === 'HRK') {
                        vm.invoice.currency = 'EUR';
                    }
                    let items = JSON.parse(JSON.stringify(data.invoice.items));
                    for (let i = 0; i < items.length; i++) {
                        items[i].id = undefined;
                    }
                    vm.invoice.items = items;
                    vm.invoice.payment_method_id = data.invoice.payment_method_id;
                    vm.invoice.ppo = data.invoice.ppo;
                    vm.invoice.ppo_text = data.invoice.ppo_text;
                    vm.invoice.discount = data.invoice.discount;
                    vm.invoice.footer = data.invoice.footer;
                    vm.invoice.tax_system = vm.$store.state.user.client.tax_system;
                    vm.invoice.locale = data.invoice.locale;
                    vm.invoice.payment_method_id = data.invoice.payment_method_id;

                    this.$store.commit('setBreadcrumbs', [
                        {
                            label: this.$LANG.Get('modules.invoices'),
                            link: '/invoices'
                        },
                        {
                            label: this.$LANG.Get('modules.newInvoice'),
                            link: null
                        }
                    ]);
                    vm.$nextTick(() => {
                        vm.loaded = true;
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        } else if (this.mode === 'cancel') {
            let vm = this;
            this.$API.APICall('get', 'invoices/' + this.invoice.id, {}, (data, success) => {
                if (success) {
                    let tempData = JSON.parse(JSON.stringify(data.invoice));
                    tempData.id = 0;
                    tempData.cancelled_by = 0;
                    tempData.cancels = parseInt(vm.$route.params.id);
                    for (let index in tempData.items) {
                        tempData.items[index].id = undefined;
                        tempData.items[index].quantity *= -1;
                    }
                    tempData.amount *= -1;
                    tempData.financial_status = 0;
                    tempData.invoice_status_id = 5;

                    tempData.paid_by_advance = false;
                    tempData.advance_text = '';
                    tempData.advance_amount = 0;

                    tempData.time_of_issue = moment().format('YYYY-MM-DD HH:mm:ss');
                    tempData.due_date = moment().format('YYYY-MM-DD');
                    tempData.date_of_delivery = moment().format('YYYY-MM-DD');

                    tempData.tax_system = vm.$store.state.user.client.tax_system;

                    tempData.customer_changed = false;

                    if (tempData.time_of_issue >= this.$store.state.settings.EUR_TRANSITION && tempData.currency === 'HRK') {
                        tempData.currency = 'EUR';
                    }

                    tempData.zki = '';
                    tempData.jir = '';

                    vm.invoice.Fill(tempData);

                    vm.$store.commit('setBreadcrumbs', [
                        {
                            label: this.$LANG.Get('modules.invoices'),
                            link: '/invoices'
                        },
                        {
                            label: this.$LANG.Get('modules.cancelInvoice'),
                            link: null
                        }
                    ]);
                    vm.$nextTick(() => {
                        vm.loaded = true;
                        vm.cancelling = true;
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        } else if (this.mode === 'offer') {
            let vm = this;
            this.$API.APICall('get', 'offers/' + this.invoice.id, {}, (data, success) => {
                if (success) {
                    vm.offerNumber = data.offer.number;

                    let tempData = JSON.parse(JSON.stringify(data.offer));
                    tempData.id = 0;
                    tempData.offer_id = vm.$route.params.id;
                    tempData.tax_system = vm.$store.state.user.client.tax_system;
                    tempData.payment_method_id = 1;
                    tempData.invoice_sequence_id = vm.$store.state.user.default_invoice_sequence_id;

                    tempData.time_of_issue = moment().format('YYYY-MM-DD HH:mm:ss');
                    tempData.due_date = moment().add(this.$store.state.user.client.invoice_due_days, 'days').format('YYYY-MM-DD');
                    tempData.date_of_delivery = moment().format('YYYY-MM-DD');

                    tempData.customer_changed = false;
                    tempData.locale = data.offer.locale;

                    tempData.footer = vm.$store.state.user.client['invoice_footer_template_' + tempData.locale] + "\n" + vm.$LANG.Get('texts.invoiceForOffer_' + tempData.locale).replace('%offer%', vm.offerNumber);

                    for (let index in tempData.items) {
                        tempData.items[index].id = undefined;
                    }

                    if (tempData.time_of_issue >= this.$store.state.settings.EUR_TRANSITION && tempData.currency === 'HRK') {
                        tempData.currency = 'EUR';
                    }

                    vm.invoice.Fill(tempData);

                    vm.$store.commit('setBreadcrumbs', [
                        {
                            label: this.$LANG.Get('modules.invoices'),
                            link: '/invoices'
                        },
                        {
                            label: this.$LANG.Get('modules.invoiceFromOffer') + ' ' + vm.offerNumber,
                            link: null
                        }
                    ]);
                    vm.$nextTick(() => {
                        vm.loaded = true;
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        } else if (this.mode === 'work-order') {
            let vm = this;
            this.$API.APICall('get', 'work-orders/' + this.invoice.id, {}, (data, success) => {
                if (success) {
                    vm.workOrderNumber = data.work_order.number;

                    let tempData = JSON.parse(JSON.stringify(data.work_order));
                    tempData.id = 0;
                    tempData.work_order_id = vm.$route.params.id;
                    tempData.tax_system = vm.$store.state.user.client.tax_system;
                    tempData.invoice_sequence_id = vm.$store.state.user.default_invoice_sequence_id;
                    tempData.payment_method_id = 1;

                    tempData.time_of_issue = moment().format('YYYY-MM-DD HH:mm:ss');
                    tempData.due_date = moment().add(this.$store.state.user.client.invoice_due_days, 'days').format('YYYY-MM-DD');
                    tempData.date_of_delivery = moment().format('YYYY-MM-DD');

                    tempData.customer_changed = false;
                    tempData.locale = data.work_order.locale;

                    tempData.footer = vm.$store.state.user.client['invoice_footer_template_' + tempData.locale] + "\n" + vm.$LANG.Get('texts.invoiceForWorkOrder_' + tempData.locale).replace('%work_order%', vm.workOrderNumber);

                    for (let index in tempData.items) {
                        tempData.items[index].id = undefined;
                    }

                    vm.invoice.Fill(tempData);

                    vm.$store.commit('setBreadcrumbs', [
                        {
                            label: this.$LANG.Get('modules.invoices'),
                            link: '/invoices'
                        },
                        {
                            label: this.$LANG.Get('modules.invoiceFromWorkOrder') + ' ' + vm.workOrderNumber,
                            link: null
                        }
                    ]);
                    vm.$nextTick(() => {
                        vm.loaded = true;
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        }
    }
}
</script>

<style scoped>
.tabs {
    margin-bottom: 10px;
    border: 2px #b7bcc0 solid;
    border-bottom: none;
    padding: 5px;
    background: #d7e3ea;
    border-radius: 5px 5px 0 0;
}

.tabs .nevs-button {
    border-radius: 0;
    margin-right: 10px;
    background: none;
    color: #5e6278;
    padding-bottom: 2px;
}

.tabs .nevs-button.active {
    border-bottom: 2px dotted #5e6278;
}

.tab-body {
    padding: 10px;
    margin-top: -10px;
    border: 2px #b7bcc0 solid;
    border-radius: 0 0 5px 5px;
}

.form {
    max-width: 300px;
    margin-bottom: 10px;
}

.form-float {
    width: 300px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 15px;
}

.form-float h2 {
    margin: 0 0 5px;
}

.customer-details {
    margin: 5px;
}

.items-card {
    clear: both;
}

.items-container {
    overflow-x: auto;
    white-space: nowrap;
}

.footer-container {
    margin-top: 10px;
    width: calc(100% - 20px);
}

.item-select {
    max-width: 500px;
}

.items-table {
    width: 100%;
    min-width: 1000px;
}

.items-table tr:first-of-type td {
    font-size: 12px;
    padding-left: 5px;
}

.items-table-buttons {
    text-align: center;
}

.items-table-buttons span {
    cursor: pointer;
}

.summary-table {
    float: right;
    margin-top: 30px;
    width: 400px;
}

.payments {
    width: calc(100% - 20px);
    max-width: 300px;
}

.payments-message {
    margin-bottom: 10px;
}

.payments-list {
    margin-bottom: 10px;
}

.payments table {
    width: 100%;
}

.payment-buttons {
    text-align: center;
    width: 60px;
}

.payment {
    border-bottom: 1px solid #c6d8e2;
}

.payment-button {
    cursor: pointer;
}

.nevs-popup {
    max-width: 300px;
}

.payment-date {
    font-size: 12px;
}

.fiskal-notice {
    padding: 5px;
}

.bank-field {
    max-width: 400px;
}

@media only screen and (max-width: 500px) {
    .summary-table {
        float: none;
    }
}

</style>