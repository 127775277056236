<template>
    <NevsCard
        :class="{'login-card': mode!=='registration' && mode!=='expired', 'wide-card': mode==='registration' || mode==='expired'}">
        <div class="logo-container">
            <img :src="logo"/>
        </div>
        <template v-if="mode==='login'">
            <NevsTextField v-model="loginData.email" :label="$LANG.Get('fields.email')+':'" :name="'email'"
                           :width="'100%'"></NevsTextField>
            <NevsTextField v-model="loginData.password" :label="$LANG.Get('fields.password')+':'" :name="'password'"
                           :type="'password'" :width="'100%'"
                           @keyup.enter="loginClick()"></NevsTextField>
            <div class="login-card-forgotten-password-button" @click="passwordRecoveryToggle">
                {{ $LANG.Get('labels.forgottenPassword') }}
            </div>
            <NevsCheckbox v-model="loginData.remember" :label="$LANG.Get('labels.rememberLogin')"></NevsCheckbox>
            <div class="login-card-footer">
                <NevsButton @click="registrationToggle">{{ $LANG.Get('buttons.registration') }}</NevsButton>
                <NevsButton class="nevs-float-right" @click="loginClick">{{ $LANG.Get('buttons.login') }}</NevsButton>
            </div>
        </template>
        <template v-if="mode==='recovery'">
            <div class="login-card-forgotten-password-explanation">{{
                    $LANG.Get('texts.forgottenPasswordExplanation')
                }}
            </div>
            <NevsTextField v-model="passwordResetData.email" :label="$LANG.Get('fields.email')+':'" :name="'email'"
                           :width="'100%'"
                           class="login-card-forgotten-password-email"></NevsTextField>
            <div class="login-card-footer">
                <NevsButton @click="passwordRecoveryToggle">{{
                        $LANG.Get('buttons.cancel')
                    }}
                </NevsButton>
                <NevsButton class="login-card-forgotten-password-recovery-button" @click="passwordRecoveryClick">{{
                        $LANG.Get('buttons.recoverPassword')
                    }}
                </NevsButton>
            </div>
        </template>
        <template v-if="mode==='registration'">
            <h2>{{ $LANG.Get('labels.yourData') }}</h2>
            <NevsTextField :error="registrationValidationData.first_name" v-model="registrationData.first_name"
                           :label="$LANG.Get('fields.firstName')+':'" :width="'50%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.last_name" v-model="registrationData.last_name"
                           :label="$LANG.Get('fields.lastName')+':'" :width="'50%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.email" v-model="registrationData.email"
                           :label="$LANG.Get('fields.email')+':'" :width="'100%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.password" :type="'password'"
                           v-model="registrationData.password" :label="$LANG.Get('fields.password')+':'"
                           :width="'50%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.repeated_password" :type="'password'"
                           v-model="registrationData.repeated_password"
                           :label="$LANG.Get('fields.repeatedPassword')+':'" :width="'50%'"></NevsTextField>
            <br/><br/>
            <h2>{{ $LANG.Get('labels.companiesData') }}</h2>
            <NevsTextField :error="registrationValidationData.name" v-model="registrationData.name"
                           :label="$LANG.Get('fields.name')+':'" :width="'50%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.oib" v-model="registrationData.oib"
                           :label="$LANG.Get('fields.oib')+':'" :width="'50%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.iban" v-model="registrationData.iban"
                           :label="$LANG.Get('fields.iban')+':'" :width="'100%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.address" v-model="registrationData.address"
                           :label="$LANG.Get('fields.streetAndHouseNo')+':'" :width="'100%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.postal_code" v-model="registrationData.postal_code"
                           :label="$LANG.Get('fields.postalCode')+':'" :width="'50%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.city" v-model="registrationData.city"
                           :label="$LANG.Get('fields.city')+':'" :width="'50%'"></NevsTextField>
            <NevsTextField :error="registrationValidationData.country" v-model="registrationData.country"
                           :label="$LANG.Get('fields.country')+':'" :width="'100%'"></NevsTextField>
            <NevsCheckbox v-model="registrationData.tax_system" :label="$LANG.Get('fields.taxSystem')"></NevsCheckbox>
            <NevsCheckbox v-model="registrationData.terms_and_conditions"
                          :label="$LANG.Get('labels.termsAndConditions')"></NevsCheckbox>
            <NevsCheckbox v-model="registrationData.privacy_policy"
                          :label="$LANG.Get('labels.privacyPolicy')"></NevsCheckbox>
            <div class="login-card-footer">
                <NevsButton @click="registrationToggle">{{
                        $LANG.Get('buttons.cancel')
                    }}
                </NevsButton>
                <NevsButton class="nevs-float-right" @click="registerClick">{{
                        $LANG.Get('buttons.register')
                    }}
                </NevsButton>
            </div>
        </template>
        <template v-if="mode==='expired'">
            <div class="expired-text" v-html="expiredText"></div>
            <NevsButton @click="subscribeClick('m')">{{ monthlyButtonText }}</NevsButton>&nbsp;&nbsp;
            <NevsButton @click="subscribeClick('y')">{{ yearlyButtonText }}</NevsButton>
        </template>
        <div class="nevs-clear-float"></div>
        <div class="login-disclaimer" v-html="$LANG.Get('labels.loginFooter')"></div>
    </NevsCard>
</template>

<script>
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";

export default {
    name: "LoginForm",
    components: {
        NevsCheckbox,
        NevsTextField,
        NevsCard,
        NevsButton
    },
    props: {
        postLogin: {
            type: Function
        }
    },
    data() {
        return {
            logo: process.env.BASE_URL + 'logo.png',
            loginData: {
                email: '',
                password: '',
                remember: false
            },
            passwordResetData: {
                email: ''
            },
            registrationData: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                repeated_password: '',
                name: '',
                oib: '',
                iban: '',
                address: '',
                postal_code: '',
                city: '',
                country: '',
                tax_system: false,
                terms_and_conditions: false,
                privacy_policy: false
            },
            registrationValidationData: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                repeated_password: '',
                name: '',
                oib: '',
                iban: '',
                address: '',
                postal_code: '',
                city: '',
                country: ''
            },
            expiredText: '',
            monthlyButtonText: '',
            yearlyButtonText: '',
            subscriptionClient: null,
            mode: 'login'
        }
    },
    methods: {
        subscribeClick(period) {
            if (this.subscriptionClient === null) return;
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.extensionQuestion_' + period), callback: (response) => {
                    if (response) {
                        let payload = {
                            client_id: vm.subscriptionClient,
                            period: period
                        };
                        this.$API.APICall('post', 'public/subscribe', payload, (data, success) => {
                            if (success) {
                                vm.loginClick();
                            } else {
                                vm.$LOCAL_BUS.TriggerEvent('popup', {
                                    text: vm.$LANG.Get('alerts.serverError'),
                                    type: 'alert'
                                });
                            }
                        })
                    }
                }
            });
        },
        registerClick() {
            if (!this.registrationData.privacy_policy || !this.registrationData.terms_and_conditions) {
                this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.termsAlert'), type: 'alert'});
                return;
            }

            let valid = true;

            if (this.registrationData.first_name === '') {
                valid = false;
                this.registrationValidationData.first_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.first_name = '';
            }

            if (this.registrationData.last_name === '') {
                valid = false;
                this.registrationValidationData.last_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.last_name = '';
            }

            if (this.registrationData.email === '') {
                valid = false;
                this.registrationValidationData.email = this.$LANG.Get('labels.mandatoryField');
            } else if (!this.$HELPERS.CheckEmail(this.registrationData.email)) {
                valid = false;
                this.registrationValidationData.email = this.$LANG.Get('labels.invalidEmailAddress');
            } else {
                this.registrationValidationData.email = '';
            }

            if (this.registrationData.password === '') {
                valid = false;
                this.registrationValidationData.password = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.password = '';
            }

            if (this.registrationData.repeated_password !== this.registrationData.password) {
                valid = false;
                this.registrationValidationData.repeated_password = this.$LANG.Get('labels.passwordMatchError');
            } else {
                this.registrationValidationData.repeated_password = '';
            }

            if (this.registrationData.name === '') {
                valid = false;
                this.registrationValidationData.name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.name = '';
            }

            if (this.registrationData.oib === '') {
                valid = false;
                this.registrationValidationData.oib = this.$LANG.Get('labels.mandatoryField');
            } else if (!this.$HELPERS.CheckOIB(this.registrationData.oib)) {
                valid = false;
                this.registrationValidationData.oib = this.$LANG.Get('labels.invalidOIB');
            } else {
                this.registrationValidationData.oib = '';
            }

            if (this.registrationData.iban === '') {
                valid = false;
                this.registrationValidationData.iban = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.iban = '';
            }

            if (this.registrationData.address === '') {
                valid = false;
                this.registrationValidationData.address = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.address = '';
            }

            if (this.registrationData.postal_code === '') {
                valid = false;
                this.registrationValidationData.postal_code = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.postal_code = '';
            }

            if (this.registrationData.city === '') {
                valid = false;
                this.registrationValidationData.city = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.city = '';
            }

            if (this.registrationData.country === '') {
                valid = false;
                this.registrationValidationData.country = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.registrationValidationData.country = '';
            }

            if (valid) {
                let vm = this;
                this.$API.APICall('post', 'public/register', this.registrationData, (data, success) => {
                    if (success) {
                        vm.registrationToggle();
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.registered'), type: 'alert'});
                    } else if (data.error !== undefined && data.error === 'oib exists') {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                            text: vm.$LANG.Get('alerts.clientOibDuplicate'),
                            type: 'alert'
                        });
                    } else if (data.error !== undefined && data.error === 'email exists') {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                            text: vm.$LANG.Get('alerts.clientEmailDuplicate'),
                            type: 'alert'
                        });
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                });
            }
        },
        passwordRecoveryClick() {
            let vm = this;
            this.$API.APICall('post', 'public/password-reset', this.passwordResetData, (data, success) => {
                if (success) {
                    vm.passwordRecoveryToggle();
                    vm.$LOCAL_BUS.TriggerEvent('popup', {
                        text: vm.$LANG.Get('alerts.passwordResetSuccessful'),
                        type: 'alert'
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        passwordRecoveryToggle() {
            this.mode = (this.mode === 'login') ? 'recovery' : 'login';
            this.passwordResetData = {
                email: ''
            };
            this.loginData = {
                email: '',
                password: '',
                remember: false
            };
        },
        registrationToggle() {
            this.mode = (this.mode === 'login') ? 'registration' : 'login';
            this.registrationData = {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                repeated_password: '',
                name: '',
                oib: '',
                iban: '',
                address: '',
                postal_code: '',
                city: '',
                country: '',
                tax_system: false,
                terms_and_conditions: false,
                privacy_policy: false
            };
        },
        expiredToggle() {
            this.mode = (this.mode === 'login') ? 'expired' : 'login';
        },
        loginClick() {
            let vm = this;
            this.$API.APICall('post', 'public/login', this.loginData, (data, success) => {
                if (success) {
                    vm.$store.commit('setUser', data.user);
                    vm.$store.commit('setLocale', data.user.locale);
                    vm.postLogin();
                } else {
                    if (data.error !== undefined) {
                        if (data.error === 'account expired') {
                            let expiredText = vm.$LANG.Get('texts.accountExpired');
                            expiredText = expiredText.replace('%email%', data.email);
                            expiredText = expiredText.replace('%monthly_end%', data.monthly_end);
                            expiredText = expiredText.replace('%yearly_end%', data.yearly_end);
                            expiredText = expiredText.replace('%legal_data%', data.legal_data);
                            let monthlyButtonText = vm.$LANG.Get("buttons.subscriptionExtend");
                            monthlyButtonText = monthlyButtonText.replace('%date_end%', data.monthly_end);
                            monthlyButtonText = monthlyButtonText.replace('%amount%', '37,67');
                            monthlyButtonText = monthlyButtonText.replace('%amount_eur%', '5');
                            let yearlyButtonText = vm.$LANG.Get("buttons.subscriptionExtend");
                            yearlyButtonText = yearlyButtonText.replace('%date_end%', data.yearly_end);
                            yearlyButtonText = yearlyButtonText.replace('%amount%', '376,73');
                            yearlyButtonText = yearlyButtonText.replace('%amount_eur%', '50');
                            vm.subscriptionClient = data.client_id;
                            vm.expiredText = expiredText;
                            vm.monthlyButtonText = monthlyButtonText;
                            vm.yearlyButtonText = yearlyButtonText;
                            vm.expiredToggle();
                        }
                        if (data.error === 'account blocked') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.accountBlocked'),
                                type: 'alert'
                            });
                        }
                        if (data.error === 'login failed') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.loginFailed'),
                                type: 'alert'
                            });
                        }
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                            text: vm.$LANG.Get('alerts.loginFailed'),
                            type: 'alert'
                        });
                    }
                }
            });
        }
    }
}
</script>

<style scoped>
.logo-container {
    text-align: center;
    border-bottom: 1px dotted #5e6278;
    margin-bottom: 10px;
}

.logo-container img {
    width: 100%;
    max-width: 250px;
}

.login-disclaimer {
    text-align: center;
    margin-top: 20px;
    border-top: 1px dotted #5e6278;
    padding-top: 5px;
}

.wide-card {
    width: 80%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.expired-text {
    margin-bottom: 10px;
}

</style>