<template>
  <div class="nevs-content">
    <NevsCard class="form">
      <NevsDateField v-model="interval.from" :label="$LANG.Get('fields.from')" :width="'50%'"></NevsDateField>
      <NevsDateField v-model="interval.to" :label="$LANG.Get('fields.to')" :width="'50%'"></NevsDateField>
      <div class="statistics-text">
        <strong>{{ $LANG.Get('labels.statisticsTotalInvoices') }}:</strong> {{ statisticalData.sums.invoices_display }}<br/>
        <strong>{{ $LANG.Get('labels.statisticsTotalPayments') }}:</strong> {{ statisticalData.sums.payments_display }}
      </div>
    </NevsCard>
    <div class="nevs-clear-float"></div>
    <NevsCard v-if="loaded" class="charts">
      <GChart
          type="SteppedAreaChart"
          :data="statisticalData.chart_amounts"
          :options="chartsOptions.amounts"
      />
      <GChart
          type="AreaChart"
          :data="statisticalData.chart_quantities"
          :options="chartsOptions.quantities"
      />
    </NevsCard>
    <div class="nevs-clear-float"></div>
    <NevsCard class="form-table">
      <h2>{{ $LANG.Get('labels.statisticsProducts') }}</h2>
      <div class="dashboard-table-container">
        <table class="dashboard-table">
          <tr>
            <th class="dashboard-table-name">{{ $LANG.Get('fields.name') }}</th>
            <th class="dashboard-table-data">{{ $LANG.Get('fields.quantity') }}</th>
            <th class="dashboard-table-data">{{ $LANG.Get('fields.amount') }}</th>
          </tr>
          <tr v-for="(product, key) in statisticalData.by_product" :key="key">
            <td class="dashboard-table-name">{{ product.name }}</td>
            <td class="dashboard-table-data">{{ product.quantity_display }}</td>
            <td class="dashboard-table-data">{{ product.amount_display }}</td>
          </tr>
        </table>
      </div>
    </NevsCard>
    <NevsCard class="form-table">
      <h2>{{ $LANG.Get('labels.statisticsCustomers') }}</h2>
      <div class="dashboard-table-container">
        <table class="dashboard-table">
          <tr>
            <th class="dashboard-table-name">{{ $LANG.Get('fields.name') }}</th>
            <th class="dashboard-table-data">{{ $LANG.Get('labels.issued') }}</th>
            <th class="dashboard-table-data">{{ $LANG.Get('labels.debt') }}</th>
          </tr>
          <tr :class="{'dashboard-table-error': customer.open > 0}" v-for="(customer, key) in statisticalData.by_customer" :key="key">
            <td class="dashboard-table-name">{{ customer.name }}</td>
            <td class="dashboard-table-data">{{ customer.issued_display }}</td>
            <td class="dashboard-table-data">{{ customer.open_display }}</td>
          </tr>
        </table>
      </div>
    </NevsCard>
  </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
import NevsDateField from "@/components/nevs/NevsDateField";
import moment from "moment";
import { GChart } from 'vue-google-charts'

export default {
  name: "ModuleDashboard",
  components: {NevsDateField, NevsCard, GChart},
  data() {
    return {
      interval: {
        from: moment().startOf('year').format('YYYY-MM-DD'),
        to: moment().endOf('year').format('YYYY-MM-DD')
      },
      statisticalData: {
        sums: {
          invoices: 0,
          invoices_display: '0,00 HRK',
          payments: 0,
          payments_display: '0,00 HRK'
        },
        by_product: [],
        by_customer: [],
        chart_amounts: [],
        chart_quantities: []
      },
      loaded: false,
      chartsOptions: {
        amounts: {
          colors: ['#81D4FA', '#FF8A65'],
          title: this.$LANG.Get('labels.amountsByMonth'),
          titleTextStyle: {
            color: '#B0BEC5'
          },
          vAxis: {
            format: '# ' + this.$HELPERS.GetDefaultCurrency(),
            gridlines: {
              color: 'transparent'
            },
            textStyle: {color: '#B0BEC5'}
          },
          hAxis: {
            textStyle: {color: '#B0BEC5'},
          },
          legend: {
            textStyle: {color: '#B0BEC5'}
          }
        },
        quantities: {
          colors: ['#81D4FA', '#FF8A65'],
          title: this.$LANG.Get('labels.quantitiesByMonth'),
          titleTextStyle: {
            color: '#B0BEC5'
          },
          vAxis: {
            format: '#',
            gridlines: {
              color: 'transparent'
            },
            textStyle: {color: '#B0BEC5'}
          },
          hAxis: {
            textStyle: {color: '#B0BEC5'},
          },
          legend: {
            textStyle: {color: '#B0BEC5'}
          }
        }
      }
    }
  },
  watch: {
    'interval': {
      handler() {
        this.loadStatisticalData()
      },
      deep: true
    }
  },
  methods: {
    loadStatisticalData() {
      let vm = this;
      this.$API.APICall('get', 'statistics/dashboard', this.interval, (data, success) => {
        if (success) {
          vm.statisticalData = data;
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
        vm.$nextTick(() => {
          vm.loaded = true;
        });
      });
    }
  },
  mounted() {
    this.$store.commit('selectMenu', 'dashboard');
    this.$store.commit('selectSubMenu', null);
    this.$store.commit('setBreadcrumbs', [
      {
        label: this.$LANG.Get('modules.dashboard'),
        link: null
      }
    ]);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);

    this.loadStatisticalData();
  }
}
</script>

<style scoped>
.form {
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
  float: left;
  margin-right: 10px;
}

.form-table {
  box-sizing: border-box;
  width: calc(50% - 10px);
  margin-bottom: 10px;
  float: left;
  margin-right: 10px;
}

.charts {
  box-sizing: border-box;
  width: calc(100% - 10px);
  margin-bottom: 10px;
  margin-right: 10px;
}

.dashboard-table-container {
  width: 100%;
  overflow-x: auto;
}

.dashboard-table {
  border-collapse: collapse;
  min-width: 100%;
}

.dashboard-table th {
  border-bottom: 1px solid black;
}
.dashboard-table td {
  border-bottom: 1px solid #d5d5d5;
}

.dashboard-table-name {
  text-align: left;
  min-width: 200px;
}

.dashboard-table-data {
  width: 200px;
  max-width: 150px;
  min-width: 150px;
  text-align: right;
}

.dashboard-table-error {
  background: #ffc1cb;
}

.statistics-text {
  margin: 10px 5px 5px;
}

@media only screen and (max-width: 1100px) {
  .form {
    max-width: none;
  }

  .form-table {
    width: 100%
  }

  .charts {
    width: 100%;
  }
}
</style>