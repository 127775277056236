<template>
  <div class="nevs-content">
    <NevsCard class="form">
      <NevsSelect :label="$LANG.Get('fields.year')" v-model="formData.year" :options="years"></NevsSelect>
      <NevsTextField :label="$LANG.Get('fields.owner')" v-model="formData.owner"></NevsTextField>
      <NevsTextField :label="$LANG.Get('fields.ownerAddress')" v-model="formData.owner_address"></NevsTextField>
      <NevsTextField :label="$LANG.Get('fields.businessName')" v-model="formData.business_name"></NevsTextField>
      <NevsTextField :label="$LANG.Get('fields.businessAddress')" v-model="formData.business_address"></NevsTextField>
      <table class="periods">
        <tr>
          <th colspan="3">{{ $LANG.Get('labels.businessPeriods') }}</th>
        </tr>
        <tr>
          <th>{{ $LANG.Get('fields.from') }}</th>
          <th>{{ $LANG.Get('fields.to') }}</th>
          <th class="periods-delete-column"></th>
        </tr>
        <tr v-for="(period, key) in formData.periods" :key="key">
          <td><NevsDateField v-model="period.from"></NevsDateField></td>
          <td><NevsDateField v-model="period.to"></NevsDateField></td>
          <td><span class="delete-icon" @click="deletePeriod(key)"><i class="fa-solid fa-trash"></i></span></td>
        </tr>
        <tr>
          <td colspan="3">
            <NevsButton @click="addPeriod()">{{ $LANG.Get('buttons.add') }}</NevsButton>
          </td>
        </tr>
      </table>
      <NevsNumberField :label="$LANG.Get('fields.income') + ' (' + currentCurrency + ')'" v-model="formData.income"></NevsNumberField>
      <NevsNumberField :label="$LANG.Get('fields.taxDue') + ' (' + currentCurrency + ')'" v-model="formData.tax_due"></NevsNumberField>
      <NevsNumberField :label="$LANG.Get('fields.surtax') + ' (%)'" v-model="formData.surtax"></NevsNumberField>
      <NevsNumberField :label="$LANG.Get('fields.taxPaid') + ' (' + currentCurrency + ')'" v-model="formData.tax_paid"></NevsNumberField>
      <NevsNumberField :label="$LANG.Get('fields.protectedAreasDeduction') + ' (' + currentCurrency + ')'" v-model="formData.protected_areas_deduction"></NevsNumberField>
      <NevsButton class="primary button-generate" @click="generateClick()"><i class="fa-solid fa-file-pdf"></i>
        {{ $LANG.Get('buttons.generate') }}
      </NevsButton>&nbsp;
    </NevsCard>
  </div>
</template>

<script>

import NevsCard from "@/components/nevs/NevsCard";
import NevsSelect from "@/components/nevs/NevsSelect";
import FormData from "@/models/FormData";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsButton from "@/components/nevs/NevsButton";
import NevsDateField from "@/components/nevs/NevsDateField";
import NevsNumberField from "@/components/nevs/NevsNumberField";

export default {
  name: "ModulePOSD",
  components: {NevsNumberField, NevsDateField, NevsButton, NevsTextField, NevsSelect, NevsCard},
  data() {
    return {
      loaded: false,
      formData: new FormData({year: parseInt(new Date().getFullYear())}),
      currentCurrency: 'EUR'
    }
  },
  watch: {
    'formData.year': function () {
      if (this.loaded) {
        this.loadYear();
      }
    }
  },
  computed: {
    years() {
      let years = [];
      for (let i = this.$store.state.user.first_year; i <= new Date().getFullYear(); i++) {
        years.push({
          label: i.toString(),
          value: parseInt(i)
        });
      }
      return years;
    }
  },
  methods: {
    loadYear() {
      if (this.formData.year+'-01-01 00:00:00' < this.$store.state.settings.EUR_TRANSITION) {
        this.currentCurrency = 'HRK';
      } else {
        this.currentCurrency = 'EUR';
      }
      let vm = this;
      this.$API.APICall('get', 'forms/data', {year: this.formData.year}, (data, success) => {
        if (success) {
          vm.formData.Fill(data);
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
        vm.$nextTick(() => {
          vm.loaded = true;
        });
      });
    },
    generateClick() {
      let vm = this;
      this.$API.APICall('post', 'forms/posd', this.formData, (data, success) => {
        if (success) {
          window.open(data.url);
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
      });
    },
    addPeriod() {
      this.formData.periods.push({
        from: null,
        to: null
      });
    },
    deletePeriod(key) {
      let vm = this;
      this.$LOCAL_BUS.TriggerEvent('popup', {
        type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
          if (response) {
            vm.formData.periods.splice(key, 1);
          }
        }
      });
    }
  },
  mounted() {
    this.$store.commit('selectMenu', 'forms');
    this.$store.commit('selectSubMenu', 'posd');
    this.$store.commit('setBreadcrumbs', [
      {
        label: this.$LANG.Get('modules.posd'),
        link: null
      }
    ]);
    this.loadYear();
  }
}

</script>

<style scoped>
.form {
  max-width: 500px;
  margin-bottom: 10px;
}

.button-generate {
  margin-top: 10px;
}
.periods {
  margin: 5px;
  width: calc(100% - 10px);
  border-collapse: collapse;
  box-sizing: border-box;
}

.periods th {
  border: 1px solid #b2b2b2;
  padding: 10px;
}

.periods td {
  border: 1px solid #b2b2b2;
  padding: 10px;
}

.periods tr:first-child th {
  border: none !important;
}

.periods-delete-column {
  width: 20px;
  text-align: center;
}

.delete-icon {
  cursor: pointer;
}
</style>