<template>
  <div class="nevs-content">
    <NevsCard class="form">
      <NevsSelect v-model="formData.year" :label="$LANG.Get('fields.year')" :options="years"></NevsSelect>
      <NevsTextField v-model="formData.owner" :label="$LANG.Get('fields.owner')"></NevsTextField>
      <NevsTextField v-model="formData.owner_address" :label="$LANG.Get('fields.ownerAddress')"></NevsTextField>
      <NevsTextField v-model="formData.main_activity_name"
                     :label="$LANG.Get('fields.mainActivityName')"></NevsTextField>
      <NevsTextField v-model="formData.main_activity_code"
                     :label="$LANG.Get('fields.mainActivityCode')"></NevsTextField>
      <NevsButton class="primary button-generate" @click="generateClick()"><i class="fa-solid fa-file-pdf"></i>
        {{ $LANG.Get('buttons.generate') }}
      </NevsButton>&nbsp;
    </NevsCard>
  </div>
</template>

<script>

import NevsCard from "@/components/nevs/NevsCard";
import NevsSelect from "@/components/nevs/NevsSelect";
import FormData from "@/models/FormData";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsButton from "@/components/nevs/NevsButton";

export default {
  name: "ModuleKPR",
  components: {NevsButton, NevsTextField, NevsSelect, NevsCard},
  data() {
    return {
      loaded: false,
      formData: new FormData({year: parseInt(new Date().getFullYear())})
    }
  },
  watch: {
    'formData.year': function () {
      if (this.loaded) {
        this.loadYear();
      }
    }
  },
  computed: {
    years() {
      let years = [];
      for (let i = this.$store.state.user.first_year; i <= new Date().getFullYear(); i++) {
        years.push({
          label: i.toString(),
          value: parseInt(i)
        });
      }
      return years;
    }
  },
  methods: {
    loadYear() {
      let vm = this;
      this.$API.APICall('get', 'forms/data', {year: this.formData.year}, (data, success) => {
        if (success) {
          vm.formData.Fill(data);
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
        vm.$nextTick(() => {
          vm.loaded = true;
        });
      });
    },
    generateClick() {
      let vm = this;
      this.$API.APICall('post', 'forms/kpr', this.formData, (data, success) => {
        if (success) {
          window.open(data.url);
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
      });
    }
  },
  mounted() {
    this.$store.commit('selectMenu', 'forms');
    this.$store.commit('selectSubMenu', 'kpr');
    this.$store.commit('setBreadcrumbs', [
      {
        label: this.$LANG.Get('modules.kpr'),
        link: null
      }
    ]);
    this.loadYear();
  }
}

</script>

<style scoped>
.form {
  max-width: 500px;
  margin-bottom: 10px;
}

.button-generate {
  margin-top: 10px;
}
</style>