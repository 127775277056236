<template>
  <div class="nevs-content">
    <NevsCard class="form">
      <NevsDateField v-model="selectedDate"></NevsDateField>
        <div class="method-container" v-for="(method, methodKey) in report" :key="methodKey">
          <div class="method-name">{{ method.name }}</div>
          <div class="value-line" v-for="(value, valueKey) in method.values" :key="valueKey">
            {{ value.display }}
          </div>
          <div v-if="method.values.length === 0" class="value-line">
            0,00 {{ currentCurrency }}
          </div>
        </div>
    </NevsCard>
  </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
import NevsDateField from "@/components/nevs/NevsDateField";
export default {
  name: "ModuleDailyReport",
  components: {NevsDateField, NevsCard},
  data() {
    return {
      loaded: false,
      selectedDate: '',
      report: [],
      currentCurrency: 'EUR'
    }
  },
  watch: {
    selectedDate() {
      if (this.selectedDate + ' 00:00:00' < this.$store.state.settings.EUR_TRANSITION) {
        this.currentCurrency = 'HRK';
      } else {
        this.currentCurrency = 'EUR';
      }
      let vm = this;
      this.$API.APICall('get', 'statistics/daily', { date: this.selectedDate }, (data, success) => {
        if (success) {
          vm.report = data;
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
        vm.$nextTick(() => {
          vm.loaded = true;
        });
      });
    }
  },
  mounted() {
    this.$store.commit('selectMenu', 'dailyReport');
    this.$store.commit('selectSubMenu', null);
    this.$store.commit('setBreadcrumbs', [
      {
        label: this.$LANG.Get('modules.dailyReport'),
        link: null
      }
    ]);

    let moment = require('moment');
    this.selectedDate = moment().format('YYYY-MM-DD');
  }
}
</script>

<style scoped>
.form {
  max-width: 300px;
  margin-bottom: 10px;
}

.method-container {
  margin: 10px 0 10px 10px;
}

.method-name {
  font-weight: bold;
  font-size: 14px;
}

.value-line {
  padding-left: 20px;
}
</style>