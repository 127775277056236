<template>
    <div class="nevs-content">
        <ModalEInvoice @done="eInvoiceDone" :show="eInvoiceModal.show" :invoiceId="eInvoiceModal.invoiceId"></ModalEInvoice>
        <div class="nevs-content-top-buttons">
            <NevsButton class="primary help-add" @click="addClick">
                <i class="fa-solid fa-plus"></i>
                {{ $LANG.Get('buttons.add') }}
            </NevsButton>
            <NevsButton class="primary" @click="filterClick">
                <i class="fa-solid fa-filter"></i>
                {{ $LANG.Get('buttons.filter') }}
            </NevsButton>
            <NevsButton class="primary" @click="excelClick">
                <i class="fa-solid fa-file-excel"></i>
                {{ $LANG.Get('buttons.excel') }}
            </NevsButton>
            <NevsButton class="primary" @click="zipClick">
                <i class="fa-solid fa-file-archive"></i>
                {{ $LANG.Get('buttons.zip') }}
            </NevsButton>
        </div>
        <NevsCard v-if="showFilters" class="filters">
            <NevsTextField v-model="tableData.filters.number" :label="$LANG.Get('fields.number')"></NevsTextField>
            <NevsSelect :nullable="true" v-model="tableData.filters.customer_id" :ajax="'select/customers'"
                        :label="$LANG.Get('fields.customer')"></NevsSelect>
            <NevsDateField v-model="tableData.filters.date_of_issue_from"
                           :label="$LANG.Get('fields.date_of_issue_from')"></NevsDateField>
            <NevsDateField v-model="tableData.filters.date_of_issue_to"
                           :label="$LANG.Get('fields.date_of_issue_to')"></NevsDateField>
            <NevsSelect :nullable="true" v-model="tableData.filters.invoice_status_id" :ajax="'select/invoice-statuses'"
                        :label="$LANG.Get('fields.status')"></NevsSelect>
            <NevsTextField v-model="tableData.filters.item" :label="$LANG.Get('fields.item')"></NevsTextField>
        </NevsCard>
        <NevsCard>
            <NevsTable
                :default-sort="tableData.defaultSort"
                :fields="tableData.fields"
                :height="tableData.height"
                :total-records="tableData.totalRecords"
                @reload="reloadTable">
                <tr v-for="(item, key) in tableData.records" :key="key">
                    <td class="nevs-table-linked-cell help-edit" @click="openClick(item.id)">{{ item.number }}<span v-if="item.e_invoice_id != null"> (E)</span></td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.date_of_issue_display }}</td>
                    <td class="nevs-table-linked-cell short-customer" @click="openClick(item.id)">
                        {{ item.customer_display }}
                    </td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)"><span
                        :class="'status-pill-' + item.invoice_status_id">{{ item.status_display }}</span></td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.links_display }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.amount_display }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{
                            item.financial_status_display
                        }}
                    </td>
                    <td class="nevs-table-linked-cell short-payment-method" @click="openClick(item.id)">
                        {{ item.payment_method_display }}
                    </td>
                    <td v-if="tableData.fields.length === 10">
                        <span v-if="item.zki !== '' && item.jir !== ''" class="nevs-text-success"><i
                            class="fa-solid fa-circle-check"></i></span>
                        <span :title="$LANG.Get('tooltips.fiskal')" @click="fiskalClick(item.id)"
                              v-if="item.zki !== '' && item.jir === ''" class="nevs-text-error fiskal-button"><i
                            class="fa-solid fa-circle-minus"></i></span>
                    </td>
                    <td class="nevs-table-actions help-actions">
                        <span v-show="item.deletable" :title="$LANG.Get('tooltips.delete')" class="nevs-table-button"
                            @click="deleteClick(item)"><i class="fa-solid fa-trash"></i></span>
                        <span v-show="item.cancellable" :title="$LANG.Get('tooltips.cancel')" class="nevs-table-button"
                              @click="cancelClick(item.id)"><i class="fa-solid fa-times"></i></span>
                        <span :title="$LANG.Get('tooltips.openInNewWindow')" class="nevs-table-button"
                              @click="openClick(item.id, true)"><i class="fa-solid fa-up-right-from-square"></i></span>
                        <span v-show="item.invoice_status_id !== 5" :title="$LANG.Get('tooltips.copy')"
                              class="nevs-table-button"
                              @click="copyClick(item.id)"><i class="fa-solid fa-clone"></i></span>
                        <span :title="$LANG.Get('tooltips.email')" class="nevs-table-button"
                              @click="emailClick(item)"><i class="fa-solid fa-envelope"></i></span>
                        <span v-if="$store.state.user.client.e_invoices_token != '' && item.payment_method_id == 1 && item.e_invoice_id == null" :title="$LANG.Get('tooltips.eInvoice')" class="nevs-table-button"
                              @click="eInvoiceClick(item)"><i class="fa-solid fa-globe"></i></span>
                        <span :title="$LANG.Get('tooltips.pdf')" class="nevs-table-button"
                              @click="pdfClick(item.pdf_link)"><i class="fa-solid fa-file-pdf"></i></span>
                    </td>
                </tr>
            </NevsTable>
        </NevsCard>
    </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTable from "@/components/nevs/NevsTable";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsDateField from "@/components/nevs/NevsDateField";
import NevsSelect from "@/components/nevs/NevsSelect";
import ModalEInvoice from "@/components/modals/ModalEInvoice.vue";

export default {
    name: "ModuleInvoices",
    components: {ModalEInvoice, NevsSelect, NevsDateField, NevsTextField, NevsTable, NevsButton, NevsCard},
    data() {
        return {
            eInvoiceModal: {
                show: false,
                invoiceId: null
            },
            tableData: {
                fields: [
                    {
                        name: 'number',
                        label: this.$LANG.Get('fields.number')
                    },
                    {
                        name: 'date_of_issue',
                        label: this.$LANG.Get('fields.dateOfIssue')
                    },
                    {
                        name: 'customer',
                        label: this.$LANG.Get('fields.customer'),
                        sortable: false
                    },
                    {
                        name: 'status',
                        label: this.$LANG.Get('fields.status'),
                        sortable: false
                    },
                    {
                        name: 'connections',
                        label: this.$LANG.Get('labels.connections'),
                        sortable: false
                    },
                    {
                        name: 'amount',
                        label: this.$LANG.Get('labels.amount')
                    },
                    {
                        name: 'financial_status',
                        label: this.$LANG.Get('labels.open')
                    },
                    {
                        name: 'payment_method',
                        label: this.$LANG.Get('fields.paymentMethod')
                    },
                    {
                        name: 'actions',
                        width: '50px',
                        label: ''
                    }
                ],
                filters: {
                    number: '',
                    date_of_issue_from: null,
                    date_of_issue_to: null,
                    invoice_status_id: null,
                    customer_id: null,
                    item: ''
                },
                records: [],
                lastRequest: null,
                totalRecords: 0,
                defaultSort: {
                    field: 'number',
                    descending: true
                },
                filterTimer: null,
                height: '0px'
            },
            showFilters: false
        }
    },
    watch: {
        'tableData.filters': {
            handler() {
                if (this.filterTimer !== null) {
                    clearTimeout(this.filterTimer);
                }
                let vm = this;
                this.filterTimer = setTimeout(function () {
                    vm.reloadTable(vm.tableData.lastRequest);
                    vm.filterTimerOn = false;
                }, 500);
            },
            deep: true
        }
    },
    methods: {
        eInvoiceClick(invoice) {
            this.eInvoiceModal.invoiceId = invoice.id;
            this.$nextTick(()=>{
                this.eInvoiceModal.show = true;
            });
        },
        eInvoiceDone(success) {
            this.eInvoiceModal.show = false;
            this.eInvoiceModal.invoiceId = null;
            if (success) {
                this.$CROSS_TAB_BUS.TriggerEvent('reload-invoices', {});
            }
        },
        emailClick(invoice) {
            let vm = this;
            this.$API.APICall('get', 'customers/' + invoice.customer_id, {}, (data, success) => {
                if (success) {
                    let emails = data.customer.emails;
                    let initialValue = '';
                    for (let key in emails) {
                        if (emails[key].invoices) {
                            if (initialValue !== '') {
                                initialValue += ' ; '
                            }
                            initialValue += emails[key].address;
                        }
                    }
                    vm.$LOCAL_BUS.TriggerEvent('popup', {
                        type: 'input',
                        text: vm.$LANG.Get('alerts.enterEmails'),
                        default: initialValue,
                        callback: (response) => {
                            if (response !== null) {
                                vm.$API.APICall('post', 'invoices/email/' + invoice.id, {destination: response}, (data, success) => {
                                    if (success) {
                                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                                            text: vm.$LANG.Get('alerts.emailSent'),
                                            type: 'alert'
                                        });
                                        vm.$CROSS_TAB_BUS.TriggerEvent('reload-invoices', {});
                                    }
                                });
                            }
                        }
                    });
                }
            });
        },
        pdfClick(link) {
            window.open(link);
        },
        deleteClick(invoice) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.invoiceDeletionQuestion'), callback: (response) => {
                    if (response) {
                        vm.$API.APICall('delete', 'invoices/' + invoice.id, {}, (data, success) => {
                            if (success) {
                                vm.$CROSS_TAB_BUS.TriggerEvent('reload-invoices', {});
                            } else {
                                vm.$LOCAL_BUS.TriggerEvent('popup', {
                                    text: vm.$LANG.Get('alerts.serverError'),
                                    type: 'alert'
                                });
                            }
                        });
                    }
                }
            });
        },
        filterClick() {
            this.showFilters = !this.showFilters;
            if (!this.showFilters) {
                this.tableData.filters = {
                    number: '',
                    date_of_issue_from: null,
                    date_of_issue_to: null,
                    invoice_status_id: null,
                    customer_id: null,
                    item: ''
                };
            }
        },
        excelClick() {
            let excelRequest = JSON.parse(JSON.stringify(this.tableData.lastRequest));
            excelRequest.rowsPerPage = 0;

            let vm = this;
            vm.$API.APICall('get', 'invoices/excel', excelRequest, (data, success) => {
                if (success) {
                    window.open(data.link);
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        zipClick() {
            let zipRequest = JSON.parse(JSON.stringify(this.tableData.lastRequest));
            zipRequest.rowsPerPage = 0;

            let vm = this;
            vm.$API.APICall('get', 'invoices/zip', zipRequest, (data, success) => {
                if (success) {
                    window.open(data.link);
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        copyClick(id) {
            this.$router.push('/invoices/' + id + '/copy');
        },
        cancelClick(id) {
            this.$router.push('/invoices/' + id + '/cancel');
        },
        fiskalClick(id) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.fiskalQuestion'), callback: (response) => {
                    if (response) {
                        vm.$API.APICall('post', 'invoices/fiskal/' + id, {}, (data, success) => {
                            if (success) {
                                vm.$CROSS_TAB_BUS.TriggerEvent('reload-invoices', {});
                            } else {
                                vm.$LOCAL_BUS.TriggerEvent('popup', {
                                    text: vm.$LANG.Get('alerts.fiskalError'),
                                    type: 'alert'
                                });
                            }
                        });
                    }
                }
            });
        },
        openClick(id, newWindow = false) {
            if (newWindow) {
                let routeData = this.$router.resolve('/invoices/' + id + '/edit');
                window.open(routeData.href);
            } else {
                this.$router.push('/invoices/' + id + '/edit');
            }
        },
        addClick() {
            this.$router.push('/invoices/0/add');
        },
        reloadTable(request) {
            let vm = this;
            request.filters = this.tableData.filters;
            this.tableData.lastRequest = JSON.parse(JSON.stringify(request));
            this.$API.APICall('get', 'invoices', request, (data, success) => {
                if (success) {
                    vm.tableData.records = data.records;
                    vm.tableData.totalRecords = data.total_records;
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        resolveWindowResize() {
            this.tableData.height = window.innerHeight - 205 + 'px';
        }
    },
    mounted() {
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-add',
                html: this.$LANG.Get('help.add'),
                left: 0,
                top: 0,
                width: 300
            },
            {
                class: 'help-actions',
                html: this.$LANG.Get('help.invoicesActions'),
                left: -160,
                top: 0,
                width: 300
            },
            {
                class: 'help-edit',
                html: this.$LANG.Get('help.edit'),
                left: 0,
                top: 0,
                width: 300
            }
        ]);
        this.$store.commit('selectMenu', 'invoices');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.invoices'),
                link: null
            }
        ]);
        window.addEventListener('resize', this.resolveWindowResize);
        this.resolveWindowResize();
        let vm = this;
        this.$CROSS_TAB_BUS.ListenToEvent('reload-invoices', () => {
            vm.reloadTable(vm.tableData.lastRequest);
        });
        if (this.$store.state.user.client.fiskal) {
            this.tableData.fields.splice(8, 0, {
                name: 'fiskal',
                label: this.$LANG.Get('labels.fiskal')
            })
        }
    }
}
</script>

<style scoped>
.filters {
    margin-bottom: 10px;
}

.fiskal-button {
    cursor: pointer;
}

.status-pill-1 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #00b7eb;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-2 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #f2de0b;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-3 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #81c784;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-4 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #ff3939;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-5 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #81c784;
    padding: 5px;
    border-radius: 5px;
}

>>> .filters .nevs-field {
    width: 300px !important;
}

>>> .nevs-table-header td {
    padding-bottom: 10px !important;
}

@media only screen and (max-width: 1920px) and (-webkit-device-pixel-ratio: 1.25) {
    .short-customer {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: anywhere;
    }

    .short-payment-method {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: anywhere;
    }
}
</style>