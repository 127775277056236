<template>
    <div v-if="sessionCheckDone" class="app-container">
        <NevsLoader v-if="$store.state.loaderCount > 0"></NevsLoader>
        <NevsNotification></NevsNotification>
        <LoginForm v-if="$store.state.user === null" :postLogin="setupMenu"></LoginForm>
        <template v-if="$store.state.user !== null">
            <Transition name="main-menu">
                <NevsMainMenu v-show="showMenu" :items="menu.items" :logo="menu.logo"
                              @toggleMenu="showMenu=!showMenu"></NevsMainMenu>
            </Transition>
            <NevsTopBar :breadcrumbs="$store.state.breadcrumbs" :buttons="topBarButtons"
                        @toggleMenu="showMenu=!showMenu"></NevsTopBar>
            <div class="nevs-main-content">
                <div v-if="$store.state.user.financial_status_id === 2" class="financial-warning">
                    {{ $LANG.Get('alerts.accountWarned') }}
                </div>
                <RouterView></RouterView>
            </div>
        </template>
        <NevsPopup></NevsPopup>
        <HelpOverlay @done="closeHelpOverlay" :show="helpOverlay.show" :items="helpOverlay.items"></HelpOverlay>
    </div>
</template>

<script>

import NevsLoader from "@/components/nevs/NevsLoader";
import NevsNotification from "@/components/nevs/NevsNotification";
import NevsPopup from "@/components/nevs/NevsPopup";
import LoginForm from "@/components/general/LoginForm";
import NevsMainMenu from "@/components/nevs/NevsMainMenu";
import NevsTopBar from "@/components/nevs/NevsTopBar";
import HelpOverlay from "@/components/general/HelpOverlay.vue";

export default {
    name: 'App',
    components: {
        HelpOverlay,
        NevsMainMenu,
        NevsTopBar,
        LoginForm,
        NevsPopup,
        NevsNotification,
        NevsLoader
    },
    data() {
        return {
            helpOverlay: {
                show: false,
                items: []
            },
            showMenu: true,
            sessionCheckDone: false,
            menu: {
                logo: process.env.BASE_URL + 'logo.png',
                items: []
            },
            topBarButtons: [
                {
                    icon: '<i class="fa-solid fa-right-from-bracket"></i>',
                    tooltip: this.$LANG.Get('tooltips.logout'),
                    action: () => {
                        let vm = this;
                        this.$API.APICall('post', 'logout', {}, (data, success) => {
                            if (success) {
                                vm.$store.commit('setUser', null);
                            } else {
                                vm.$LOCAL_BUS.TriggerEvent('popup', {
                                    text: vm.$LANG.Get('alerts.serverError'),
                                    type: 'alert'
                                });
                            }
                        });
                    }
                },
                {
                    icon: '<i class="fa-solid fa-user"></i>',
                    tooltip: this.$LANG.Get('tooltips.myProfile'),
                    action: () => {
                        this.$router.push('/users/' + this.$store.state.user.id);
                    }
                },
                {
                    icon: '<i class="fa-solid fa-circle-question"></i>',
                    tooltip: this.$LANG.Get('tooltips.help'),
                    action: () => {
                        this.openHelpOverlay();
                    }
                }
            ]
        }
    },
    methods: {
        closeHelpOverlay() {
            this.helpOverlay.show = false;
        },
        openHelpOverlay() {
            window.scrollTo({top: 0});
            this.helpOverlay.items = this.$store.state.helpOverlay;
            this.$nextTick(()=>{
                this.helpOverlay.show = true;
            });
        },
        resolveWindowResize() {
            this.showMenu = window.innerWidth >= 800;
        },
        setupMenu() {
            this.menu.items = [];
            this.menu.items.push({
                id: 'dashboard',
                label: this.$LANG.Get('modules.dashboard'),
                link: '/',
                icon: '<i class="fa-solid fa-tachometer-alt"></i>',
                children: []
            });
            this.menu.items.push({
                id: '---'
            });
            this.menu.items.push({
                id: 'persons',
                label: this.$LANG.Get('modules.persons'),
                link: '/persons',
                icon: '<i class="fa-solid fa-users"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'companies',
                label: this.$LANG.Get('modules.companies'),
                link: '/companies',
                icon: '<i class="fa-solid fa-industry"></i>',
                children: []
            });
            this.menu.items.push({
                id: '---'
            });
            this.menu.items.push({
                id: 'products',
                label: this.$LANG.Get('modules.products'),
                link: '/products',
                icon: '<i class="fa-solid fa-cubes"></i>',
                children: []
            });
            this.menu.items.push({
                id: '---'
            });
            this.menu.items.push({
                id: 'workOrders',
                label: this.$LANG.Get('modules.workOrders'),
                link: '/work-orders',
                icon: '<i class="fa-solid fa-person-digging"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'offers',
                label: this.$LANG.Get('modules.offers'),
                link: '/offers',
                icon: '<i class="fa-solid fa-file-invoice"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'invoices',
                label: this.$LANG.Get('modules.invoices'),
                link: '/invoices',
                icon: '<i class="fa-solid fa-file-invoice-dollar"></i>',
                children: []
            });
            this.menu.items.push({
                id: '---'
            });
            this.menu.items.push({
                id: 'dailyReport',
                label: this.$LANG.Get('modules.dailyReport'),
                link: '/daily-report',
                icon: '<i class="fa-solid fa-receipt"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'forms',
                label: this.$LANG.Get('modules.forms'),
                link: 'null',
                icon: '<i class="fa-solid fa-paste"></i>',
                children: [
                    {
                        id: 'kpr',
                        label: this.$LANG.Get('modules.kpr'),
                        link: '/kpr',
                        icon: '',
                        children: []
                    },
                    {
                        id: 'posd',
                        label: this.$LANG.Get('modules.posd'),
                        link: '/posd',
                        icon: '',
                        children: []
                    }
                ]
            });
            this.menu.items.push({
                id: '---'
            });
            if (this.$store.state.user.permissions.includes('ADMINISTRATOR')) {
                this.menu.items.push({
                    id: 'settings',
                    label: this.$LANG.Get('modules.settings'),
                    link: null,
                    icon: '<i class="fa-solid fa-cog"></i>',
                    children: [
                        {
                            id: 'generalData',
                            label: this.$LANG.Get('modules.generalData'),
                            link: '/general-data',
                            icon: '',
                            children: []
                        },
                        {
                            id: 'financialSettings',
                            label: this.$LANG.Get('modules.financialSettings'),
                            link: '/financial-settings',
                            icon: '',
                            children: []
                        },
                        {
                            id: 'emailSettings',
                            label: this.$LANG.Get('modules.emailSettings'),
                            link: '/email-settings',
                            icon: '',
                            children: []
                        },
                        {
                            id: 'users',
                            label: this.$LANG.Get('modules.users'),
                            link: '/users',
                            icon: '',
                            children: []
                        }
                    ]
                });
            }
            this.menu.items.push({
                id: '---'
            });
            this.menu.items.push({
                id: 'help',
                label: this.$LANG.Get('modules.help'),
                link: '/help',
                icon: '<i class="fa-solid fa-suitcase-medical"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'termsAndConditions',
                label: this.$LANG.Get('modules.termsAndConditions'),
                external: true,
                link: 'https://pausalko.com/uvjeti_koristenja.pdf',
                icon: '<i class="fa-solid fa-file-contract"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'gdpr',
                label: this.$LANG.Get('modules.gdpr'),
                external: true,
                link: 'https://pausalko.com/GDPR.pdf',
                icon: '<i class="fa-solid fa-user-secret"></i>',
                children: []
            });
        }
    },
    mounted() {
        window.addEventListener('resize', this.resolveWindowResize);
        this.resolveWindowResize();
        let vm = this;
        this.$API.APICall('get', 'public/version', {}, (data, success) => {
            if (success) {
                if (data.version !== this.$HELPERS.GetCookie('pausalko_version')) {
                    this.$HELPERS.SetCookie('pausalko_version', data.version);
                    window.location.reload(true);
                }
            }
        });
        this.$API.APICall('get', 'session', {}, (data, success) => {
            if (success) {
                vm.$store.commit('setUser', data.user);
                vm.$store.commit('setLocale', data.user.locale);
                vm.$nextTick(() => {
                    this.setupMenu();
                });
            } else {
                vm.$store.commit('setLocale', vm.$store.state.settings.LOCALE);
            }
            vm.$nextTick(() => {
                vm.sessionCheckDone = true;
            });
        }, false);
        setInterval(() => {
            if (vm.$store.state.user !== null) {
                this.$API.APICall('get', 'public/heartbeat', {}, (data, success) => {
                    if (success) {
                        if (!data.logged_in) {
                            vm.$store.commit('setUser', null);
                        }
                        if (data.version !== this.$HELPERS.GetCookie('pausalko_version')) {
                            this.$HELPERS.SetCookie('pausalko_version', data.version);
                            window.location.reload(true);
                        }
                    }
                }, false);
            }
        }, 30000);
    }
}

</script>

<style scoped>
@media only screen and (max-width: 1920px) and (-webkit-device-pixel-ratio: 1.25) {
    >>> .nevs-main-menu {
        font-size: 16px;
    }
}

.financial-warning {
    background: #F88379;
    padding: 10px;
    color: black;
}
</style>