<template>
  <div class="nevs-button"><slot></slot></div>
</template>

<script>
export default {
  name: "NevsButton"
}
</script>

<style scoped>

</style>