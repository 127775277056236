import moment from "moment";
import Settings from "@/config.json";

class Invoice {
    id = null;
    number = '';
    time_of_issue = null;
    fiskal_time = null;
    due_date = null;
    date_of_delivery = null;
    currency = '';
    currency_rate = 1;
    discount = 0;
    amount = 0;
    financial_status = 0;
    offer_id = null;
    work_order_id = null;
    history = [];
    tax_system = false;
    footer = '';
    paid_by_advance = false;
    advance_text = '';
    advance_amount = 0;
    user_id = null;
    customer_id = null;
    customer_data = {};
    invoice_status_id = 1;
    sequence = 0;
    device = '';
    location = '';
    invoice_sequence_id = null;
    zki = '';
    jir = '';
    hash = '';
    items = [];
    ppo = false;
    locale = '';
    payments = [];
    payment_method_id = null;
    cancels = 0;
    cancelled_by = 0;
    iban = '';
    swift = '';
    ppo_text = '';
    e_invoice_id = null;

    constructor(data, validation = false) {
        this.currency = 'EUR';
        if (moment().format('YYYY-MM-DD HH:mm:ss') < Settings.EUR_TRANSITION) {
            this.currency = 'HRK';
        }

        for (const [key, value] of Object.entries(data)) {
            this[key] = value;
        }

        if (validation) {
            for (const [key] of Object.entries(this)) {
                this[key] = '';
            }
        }
    }

    Fill(data) {
        for (const [key] of Object.entries(this)) {
            if (data[key] !== undefined) {
                this[key] = data[key];
            }
        }
    }
}

export default Invoice;
