<template>
    <div v-if="loaded" class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
        </div>
        <NevsCard class="form">
            <NevsTextField class="half" v-model="client.name" :error="clientValidation.name"
                           :label="'*' + $LANG.Get('fields.name') + ':'"></NevsTextField>
            <NevsTextField class="half" v-model="client.oib" :error="clientValidation.oib"
                           :label="'*' + $LANG.Get('fields.oib') + ':'"></NevsTextField>
            <NevsTextField class="half" v-model="client.iban" :error="clientValidation.iban"
                           :label="'*' + $LANG.Get('fields.iban') + ':'"></NevsTextField>
            <NevsTextField class="half" v-model="client.swift" :error="clientValidation.swift"
                           :label="$LANG.Get('fields.swift') + ':'"></NevsTextField>
            <NevsTextField v-model="client.address" :error="clientValidation.address"
                           :label="'*' + $LANG.Get('fields.streetAndHouseNo') + ':'"></NevsTextField>
            <NevsTextField class="half" v-model="client.postal_code" :error="clientValidation.postal_code"
                           :label="'*' + $LANG.Get('fields.postalCode') + ':'"></NevsTextField>
            <NevsTextField class="half" v-model="client.city" :error="clientValidation.city"
                           :label="'*' + $LANG.Get('fields.city') + ':'"></NevsTextField>
            <NevsTextField v-model="client.country" :error="clientValidation.country"
                           :label="'*' + $LANG.Get('fields.country') + ':'"></NevsTextField>
            <NevsCheckbox v-model="client.tax_system" :label="$LANG.Get('fields.taxSystem')"></NevsCheckbox>
        </NevsCard>
        <NevsCard class="form form-pdf help-pdf">
            <NevsUpload :accept="'image/png'" v-model="client.logo"
                        :label="$LANG.Get('fields.logo') + ':'"></NevsUpload>
            <NevsSelect :options="orientationOptions" v-model="client.pdf_orientation"
                        :label="$LANG.Get('fields.pdfOrientation') + ':'"></NevsSelect>
            <NevsNumberField :decimal-places="0" v-model="client.pdf_customer_offset_x" :label="$LANG.Get('fields.pdfOffsetCustomerX')"></NevsNumberField>
            <NevsNumberField :decimal-places="0" v-model="client.pdf_customer_offset_y" :label="$LANG.Get('fields.pdfOffsetCustomerY')"></NevsNumberField>
            <NevsButton class="form-pdf-memorandum" @click="generateMemorandumClick()"><i class="fa-solid fa-file-pdf"></i>
                {{ $LANG.Get('buttons.generateMemorandum') }}
            </NevsButton>&nbsp;
        </NevsCard>
    </div>
</template>

<script>
import Client from "@/models/Client";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsCard from "@/components/nevs/NevsCard";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";
import NevsUpload from "@/components/nevs/NevsUpload";
import NevsSelect from "@/components/nevs/NevsSelect.vue";
import NevsNumberField from "@/components/nevs/NevsNumberField.vue";

export default {
    name: "GeneralData",
    components: {NevsNumberField, NevsSelect, NevsUpload, NevsCheckbox, NevsCard, NevsTextField, NevsButton},
    data() {
        return {
            loaded: false,
            client: new Client(),
            clientValidation: new Client({}, true),
            orientationOptions: [
                {
                    'label': this.$LANG.Get('labels.left'),
                    'value': 'L'
                },
                {
                    'label': this.$LANG.Get('labels.right'),
                    'value': 'R'
                },
            ]
        }
    },
    methods: {
        generateMemorandumClick() {
            let vm = this;
            this.$API.APICall('get', 'client/memorandum', {}, (data, success) => {
                if (success) {
                    window.open(data.url);
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        saveClick() {
            let valid = true;

            if (this.client.name === '') {
                valid = false;
                this.clientValidation.name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.clientValidation.name = '';
            }

            if (this.client.oib !== '') {
                if (!this.$HELPERS.CheckOIB(this.client.oib)) {
                    valid = false;
                    this.clientValidation.oib = this.$LANG.Get('labels.invalidOIB');
                } else {
                    this.clientValidation.oib = '';
                }
            } else {
                valid = false;
                this.clientValidation.oib = this.$LANG.Get('labels.mandatoryField');
            }

            if (this.client.iban === '') {
                valid = false;
                this.clientValidation.iban = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.clientValidation.iban = '';
            }

            if (this.client.address === '') {
                valid = false;
                this.clientValidation.address = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.clientValidation.address = '';
            }

            if (this.client.postal_code === '') {
                valid = false;
                this.clientValidation.postal_code = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.clientValidation.postal_code = '';
            }

            if (this.client.city === '') {
                valid = false;
                this.clientValidation.city = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.clientValidation.city = '';
            }

            if (this.client.country === '') {
                valid = false;
                this.clientValidation.country = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.clientValidation.country = '';
            }

            if (valid) {
                let vm = this;
                this.$API.APICall('put', 'client', this.client, (data, success) => {
                    if (success) {
                        vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        vm.$API.APICall('get', 'session', {}, (data, success) => {
                            if (success) {
                                vm.$store.commit('setUser', data.user);
                            }
                        }, false);
                    } else if (data.error !== undefined && data.error === 'duplicate oib') {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                            text: vm.$LANG.Get('alerts.clientOibDuplicate'),
                            type: 'alert'
                        });
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                })
            }
        }
    },
    mounted() {
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-pdf',
                html: this.$LANG.Get('help.pdf'),
                left: 320,
                top: -300,
                width: 350
            }
        ]);
        if (!this.$store.state.user.permissions.includes('ADMINISTRATOR')) {
            this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
            this.$router.push('/');
        }
        this.$store.commit('selectMenu', 'settings');
        this.$store.commit('selectSubMenu', 'generalData');
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.settings'),
                link: null
            },
            {
                label: this.$LANG.Get('modules.generalData'),
                link: null
            }
        ]);

        let vm = this;
        this.$API.APICall('get', 'client', {}, (data, success) => {
            if (success) {
                if (data.client !== null) {
                    vm.client.Fill(data.client);
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
            vm.$nextTick(() => {
                vm.loaded = true;
            });
        });
    }
}
</script>

<style scoped>
.form {
    max-width: 600px;
}

.half {
    width: 50% !important;
}

.form-pdf {
    margin-top: 10px;
}

.form-pdf-memorandum {
    margin-top: 10px;
}

@media only screen and (max-width: 600px) {
    .half {
        width: 100% !important;
    }
}

</style>