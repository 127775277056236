class FormData {
    id = null;
    year = 0;
    business_name = '';
    business_address = '';
    owner = '';
    owner_address = '';
    main_activity_name = '';
    main_activity_code = '';
    old_posd_data = [];
    periods = [];
    income = 0;
    tax_due = 0;
    surtax = 0;
    tax_paid = 0;
    protected_areas_deduction = 0;

    constructor(data, validation = false) {
        for (const [key, value] of Object.entries(data)) {
            this[key] = value;
        }

        if (validation) {
            for (const [key] of Object.entries(this)) {
                this[key] = '';
            }
        }
    }

    Fill(data) {
        for (const [key] of Object.entries(this)) {
            if (data[key] !== undefined) {
                this[key] = data[key];
            }
        }
    }
}

export default FormData;
