<template>
    <div class="help-content">
        <h1>Postavljanje e-računa</h1>
        <p>
            Za postavljanje e-računa potrebno je napraviti nekoliko koraka.<br/>
            <ol>
                <li>Registrirati se kod posrednika za slanje e-računa <a target="_blank"
                                                                         href="https://www.ie-racuni.com/">IE
                    RAČUNI</a>.
                </li>
                <li>U postavkama sustava IE RAČUNI za API klijenta odabrati <strong>MODUL42</strong>.</li>
                <li>Kopirati vrijednost polja <strong>API TOKEN</strong> iz postavki sustava IE RAČUNI u polje <strong>Token
                    za slanje e-računa</strong> u postavkama sustava Paušalko.
                </li>
            </ol>
        </p>
        <h2>Registracija u sustav IE RAČUNI</h2>
        <p>
            Potrebno je izvršiti registraciju u sustav <a target="_blank" href="https://www.ie-racuni.com/">IE
            RAČUNI</a>.<br/>
            Predlažemo da se za više informacija o registraciji i naplati korištenja sustava IE RAČUNI obratite direktno
            njima.
        </p>
        <h2>Odabir API klijenta</h2>
        <p>
            U postavkama sustava IE RAČUNI (<strong>Postavke > API</strong>) potrebno je u polju
            <strong>KLIJENT</strong> odabrati <strong>MODUL42</strong>.
        </p>
        <h2>Kopiranje API tokena</h2>
        <p>
            U postavkama sustava IE RAČUNI (<strong>Postavke > API</strong>) potrebno je pronaći vrijednost u polju
            <strong>TOKEN</strong>.<br/>
            U sustavu Paušalko je potrebno otići u postavke (<strong>Postavke > Financije</strong>), te tu vrijednost
            upisati u polje <strong>Token za slanje e-računa</strong>.<br/>
            Na kraju je potrebno kliknutu gumb <strong>Spremi</strong> koji se nalazi na vrhu.
        </p>
        <h2>Slanje e-računa</h2>
        <p>
            Nakon što ste odradili prethodne korake u tablici s računima će se s desne strane pojaviti ikona
            globusa.<br/>
            Klikom na tu ikonu otvara se prozor pomoću kojeg šaljete e-račun.
        </p>
        <div class="disclaimer">
            Samo transakcijski računi se mogu slati kao e-računi.<br/>
            Računi koji su poslani kao e-računi se ne mogu brisati i mijenjati, ali se mogu stornirati.<br/>
            Sustav IE RAČUNI naplaćuje uslugu slanja e-računa.<br/>
            Troškove slanja ne snosi sustav Paušalko (obrt Modul42) nego ih snosite Vi kao korisnik sustava IE
            računi.<br/>
            Predlažemo da se za sva pitanja oko registracije i naplate u sustavu IE RAČUNI obratite direktno njima.
        </div>
    </div>
</template>

<script>

export default {
    name: "HelpEInvoices",
    components: {},
    data() {
        return {}
    },
}
</script>

<style scoped>
.help-content {
    background: white;
}

.help-content a {
    font-weight: bold;
}

.disclaimer {
    color: red;
    font-weight: bold;
}
</style>