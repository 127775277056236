<template>
    <div v-if="loaded" class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success help-save" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
            <NevsButton class="error help-back" @click="backClick"><i class="fa-solid fa-arrow-left"></i> {{
                    $LANG.Get('buttons.back')
                }}
            </NevsButton>
        </div>
        <NevsCard class="form form-float">
            <NevsSelect v-model="offer.customer_id" :ajax="'select/customers'" :error="offerValidation.customer_id"
                        :label="$LANG.Get('fields.customer')"></NevsSelect>
            <div v-if="this.selectedCustomer !== null" class="customer-details">
                {{ this.selectedCustomer.address }}<br/>
                {{ this.selectedCustomer.postal_code }} {{ this.selectedCustomer.city }}<br/>
                {{ this.selectedCustomer.country }}<br/>
            </div>
        </NevsCard>
        <NevsCard class="form form-float help-dates">
            <NevsDateField v-model="timeOfIssue.date" :error="timeOfIssueValidation.date"
                           :label="$LANG.Get('fields.dateOfIssue')"></NevsDateField>
            <NevsMaskedField v-model="timeOfIssue.time" :error="timeOfIssueValidation.time"
                             :label="$LANG.Get('fields.timeOfIssue')"
                             :mask="'NN:NN:NN'"></NevsMaskedField>
            <NevsDateField v-model="offer.valid_until" :error="offerValidation.valid_until"
                           :label="$LANG.Get('fields.validUntil')"></NevsDateField>
        </NevsCard>
        <NevsCard class="form form-float">
            <NevsSelect v-if="showCurrency" v-model="offer.currency" :ajax="currencyEndpoint"
                        :error="offerValidation.currency"
                        :label="$LANG.Get('fields.currency')" :readonly="currencyReadonly"></NevsSelect>
            <NevsSelect v-if="mode !== 'add' && mode !== 'copy'" v-model="offer.offer_status_id"
                        :ajax="'select/offer-statuses'" :error="offerValidation.currency"
                        :label="$LANG.Get('fields.status')"></NevsSelect>
            <NevsSelect v-if="(mode === 'add' || mode === 'copy') && sequences.length > 1"
                        v-model="offer.offer_sequence_id"
                        :error="offerValidation.offer_sequence_id" :label="$LANG.Get('fields.sequence')"
                        :options="sequences"></NevsSelect>
            <NevsSelect v-model="offer.locale"
                        :error="offerValidation.locale" :label="$LANG.Get('fields.language')"
                        :ajax="'select/languages'"></NevsSelect>
        </NevsCard>
        <NevsCard class="items-card">
            <NevsSelect v-model="selectedProduct" :ajax="'select/products'" :error="offerValidation.items"
                        :label="$LANG.Get('labels.selectItem')" class="item-select help-item"></NevsSelect>
            <div class="items-container">
                <table v-show="offer.items.length !== 0" class="items-table">
                    <tr class="items-table-header">
                        <td v-for="(header, key) in headers" :key="key" :style="{'width': header.width}">{{
                                header.text
                            }}
                        </td>
                    </tr>
                    <tr v-for="(item, key) in offer.items" :key="key">
                        <td class="items-table-buttons">
                            <span @click="removeItemClick(key)"><i class="fa-solid fa-trash"></i></span>
                        </td>
                        <td>
                            <NevsTextField v-model="item.description"></NevsTextField>
                        </td>
                        <td>
                            <NevsSelect v-model="item.measurement_unit_id"
                                        :ajax="'select/measurement-units'"></NevsSelect>
                        </td>
                        <td>
                            <NevsNumberField v-model="item.price"></NevsNumberField>
                        </td>
                        <td>
                            <NevsNumberField v-model="item.quantity" :decimal-places="3"
                                             :thousand-separator="''"></NevsNumberField>
                        </td>
                        <td v-if="offer.tax_system && !offer.ppo">
                            <NevsNumberField v-model="item.tax"></NevsNumberField>
                        </td>
                        <td>
                            <NevsNumberField v-model="item.discount"></NevsNumberField>
                        </td>
                        <td>
                            <NevsNumberField :readonly="true" v-model="item.total"></NevsNumberField>
                        </td>
                    </tr>
                </table>
                <table v-show="offer.items.length !== 0" class="summary-table">
                    <tr>
                        <td class="sub-table-left">{{ $LANG.Get('labels.additionalDiscount') }} (%):</td>
                        <td class="sub-table-right">
                            <NevsNumberField v-model="offer.discount" :thousand-separator="''"></NevsNumberField>
                        </td>
                    </tr>
                    <tr v-for="(base, key) in taxBases" :key="key">
                        <td>
                            {{ $LANG.Get('labels.taxBase') }} {{ base.base }} ({{ offer.currency }}):
                        </td>
                        <td>
                            <NevsNumberField v-model="base.value" :readonly="true"></NevsNumberField>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $LANG.Get('fields.total') }} ({{ offer.currency }}):
                        </td>
                        <td>
                            <NevsNumberField v-model="total" :readonly="true"></NevsNumberField>
                        </td>
                    </tr>
                </table>
                <div class="nevs-clear-float"></div>
            </div>
        </NevsCard>
        <NevsCard class="footer-container">
            <NevsTextArea v-model="offer.footer" :error="offerValidation.footer"
                          :label="$LANG.Get('fields.footer')"></NevsTextArea>
            <NevsCheckbox v-model="offer.ppo" :label="$LANG.Get('fields.ppo')" class="help-ppo"></NevsCheckbox>
            <NevsTextField v-if="offer.ppo" v-model="offer.ppo_text"
                           :label="$LANG.Get('fields.ppoText')" :readonly="readonly"></NevsTextField>
            <NevsSelect class="bank-field" v-if="ibanOptions.length > 1" :label="$LANG.Get('fields.iban')"
                        v-model="offer.iban" :options="ibanOptions"></NevsSelect>
            <NevsSelect class="bank-field" v-if="swiftOptions.length > 1" :label="$LANG.Get('fields.swift')"
                        v-model="offer.swift" :options="swiftOptions"></NevsSelect>
        </NevsCard>
    </div>
</template>

<script>
import Offer from '@/models/Offer';
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import Big from 'big.js';
import NevsSelect from "@/components/nevs/NevsSelect";
import NevsDateField from "@/components/nevs/NevsDateField";
import NevsMaskedField from "@/components/nevs/NevsMaskedField";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsNumberField from "@/components/nevs/NevsNumberField";
import NevsTextArea from "@/components/nevs/NevsTextArea";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";

export default {
    name: "EntityOffer",
    components: {
        NevsCheckbox,
        NevsTextArea,
        NevsNumberField, NevsTextField, NevsMaskedField, NevsDateField, NevsSelect, NevsButton, NevsCard
    },
    data() {
        return {
            loaded: false,
            mode: this.$route.params.mode,
            offer: new Offer({
                id: this.$route.params.id,
                iban: this.$store.state.user.client.iban[0],
                swift: this.$store.state.user.client.swift[0]
            }),
            offerValidation: new Offer({}, true),
            selectedCustomer: null,
            selectedProduct: null,
            taxBases: [],
            tempTaxBases: [],
            total: 0,
            currencyReadonly: false,
            originalCustomerId: null,
            newCustomerData: null,
            customerChanged: false,
            timeOfIssue: {
                date: null,
                time: null
            },
            timeOfIssueValidation: {
                date: '',
                time: ''
            },
            sequences: [],
            showCurrency: false,
            currencyEndpoint: 'select/currencies'
        }
    },
    computed: {
        headers() {
            let headers = [
                {
                    text: '',
                    sortable: false,
                    value: 'actions',
                    width: '50px',
                    class: 'pa-0'
                },
                {
                    text: this.$LANG.Get('fields.description'),
                    sortable: false,
                    value: 'description',
                    class: 'pa-0 description-width'
                },
                {
                    text: this.$LANG.Get('fields.measurementUnit'),
                    sortable: false,
                    value: 'measurement_unit',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.price') + ' (' + this.offer.currency + ')',
                    sortable: false,
                    value: 'price',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.quantity'),
                    sortable: false,
                    value: 'quantity',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.discount'),
                    sortable: false,
                    value: 'discount',
                    class: 'pa-0',
                    width: "100px"
                },
                {
                    text: this.$LANG.Get('fields.total') + ' (' + this.offer.currency + ')',
                    sortable: false,
                    value: 'total',
                    class: 'pa-0',
                    width: "100px"
                }
            ]
            if (this.offer.tax_system && !this.offer.ppo) {
                headers.splice(5, 0, {
                    text: this.$LANG.Get('fields.tax'),
                    sortable: false,
                    value: 'tax',
                    class: 'pa-0',
                    width: "100px"
                })
            }
            return headers;
        },
        ibanOptions() {
            let options = [];
            let current = this.offer.iban;
            let currentExists = false;
            for (let iban of this.$store.state.user.client.iban) {
                if (iban === current) currentExists = true;
                options.push({
                    label: iban,
                    value: iban
                });
            }
            if (!currentExists) {
                options.push({
                    label: current,
                    value: current
                });
            }
            return options;
        },
        swiftOptions() {
            let options = [];
            let current = this.offer.swift;
            let currentExists = false;
            for (let swift of this.$store.state.user.client.swift) {
                if (swift === current) currentExists = true;
                options.push({
                    label: swift,
                    value: swift
                });
            }
            if (!currentExists) {
                options.push({
                    label: current,
                    value: current
                });
            }
            return options;
        }
    },
    watch: {
        'offer.locale': function () {
            if (this.loaded) {
                let vm = this;
                for (let i = 0; i < this.offer.items.length; i++) {
                    if (this.offer.items[i].product_id !== null) {
                        this.$API.APICall('get', 'products/' + this.offer.items[i].product_id, {}, (data, success) => {
                            if (success) {
                                vm.offer.items[i].description = (data.product['name_' + vm.offer.locale] !== undefined) ? data.product['name_' + vm.offer.locale] : data.product.name;
                            }
                        }, false);
                    }
                }
                this.offer.footer = this.$store.state.user.client['offer_footer_template_' + this.offer.locale];
                this.offer.ppo_text = this.offer.ppo ? this.$store.state.user.client['ppo_template_' + this.offer.locale] : '';
            }
        },
        'offer.customer_id': function () {
            if (this.offer.customer_id != null) {
                let vm = this;
                this.$API.APICall('get', 'customers/' + this.offer.customer_id, {}, (data, success) => {
                    if (success) {
                        vm.selectedCustomer = data.customer;
                    }
                });
            } else {
                this.selectedCustomer = null;
            }
        },
        'selectedProduct': function () {
            if (this.selectedProduct != null) {
                this.addItem();
            }
        },
        'offer.items': {
            handler: function () {
                this.calculateTotals();
            },
            deep: true
        },
        'offer.ppo': {
            handler: function () {
                this.calculateTotals();
                if (this.loaded) {
                    this.offer.ppo_text = this.offer.ppo ? this.$store.state.user.client['ppo_template_' + this.offer.locale] : '';
                }
            },
            deep: true
        },
        'offer.discount': {
            handler: function () {
                this.calculateTotals();
            },
            deep: true
        },
        'offer.currency': {
            handler: function () {
                this.loadCurrencyRate();
            },
            deep: true
        },
        'timeOfIssue': {
            handler: function () {
                this.showCurrency = false;
                this.currencyEndpoint = 'select/currencies';
                if (this.timeOfIssue.date + ' ' + this.timeOfIssue.time < this.$store.state.settings.EUR_TRANSITION) {
                    this.currencyEndpoint = 'select/currencies-hrk';
                }
                this.$nextTick(() => {
                    this.offer.time_of_issue = this.timeOfIssue.date + ' ' + this.timeOfIssue.time;
                    this.showCurrency = true;
                });
            },
            deep: true
        },
        'offer.time_of_issue': {
            handler: function () {
                this.timeOfIssue.date = this.offer.time_of_issue.split(' ')[0];
                this.timeOfIssue.time = this.offer.time_of_issue.split(' ')[1];
                let moment = require('moment');
                if (this.offer.time_of_issue > moment().format('YYYY-MM-DD') + ' 23:59:59') {
                    this.offer.currency = this.$HELPERS.GetDefaultCurrency();
                    this.currencyReadonly = true;
                    this.loadCurrencyRate();
                } else {
                    this.currencyReadonly = false;
                    this.loadCurrencyRate();
                }
            },
            deep: true
        }
    },
    methods: {
        loadCurrencyRate() {
            let vm = this;
            let oldRate = this.offer.currency_rate;
            let loaded = this.loaded;
            this.$API.APICall('get', 'currencies/' + this.offer.currency, {date: this.offer.time_of_issue}, (data, success) => {
                if (success) {
                    if (data.rate != null) {
                        vm.offer.currency_rate = data.rate;
                        if (loaded) {
                            for (let index in vm.offer.items) {
                                let item = vm.offer.items[index];
                                let price = Big(item.price);
                                vm.offer.items[index]['price'] = ((price * oldRate).toFixed(2) / Big(data.rate)).toFixed(2);
                            }
                        }
                    }
                }
            }, false);
        },
        calculateTotals() {
            this.tempTaxBases = [];
            this.total = Big(0);
            for (let index in this.offer.items) {
                let item = this.offer.items[index];
                let total = Big(item.price).times(Big(item.quantity));
                total = Big(total.toFixed(2));
                let item_discount = total.times(Big(item.discount)).div(Big(100));
                item_discount = item_discount.toFixed(2);
                total = total.minus(item_discount);
                total = Big(total.toFixed(2));
                let total_with_discount = total;
                let discount_amount = total_with_discount.times(Big(this.offer.discount)).div(Big(100));
                discount_amount = Big(discount_amount.toFixed(2));
                total_with_discount = total_with_discount.minus(discount_amount);
                if (this.offer.tax_system && !this.offer.ppo) {
                    this.addToTempBase(item.tax, total_with_discount);
                }
                this.offer.items[index]['total'] = total.toFixed(2);
                this.total = this.total.add(total_with_discount.toFixed(2));
            }
            this.taxBases = JSON.parse(JSON.stringify(this.tempTaxBases));
            if (this.offer.tax_system && !this.offer.ppo) {
                this.total = Big(0);
                for (let index in this.taxBases) {
                    let currentBase = this.taxBases[index];
                    let currentTaxAmount = (Big(currentBase.value).times(Big(currentBase.base)).div(Big(100))).toFixed(2);
                    this.total = this.total.add(currentTaxAmount).add(Big(currentBase.value));
                }
            }
        },
        addToTempBase(base, value) {
            let term = false;
            for (let index in this.tempTaxBases) {
                let currentBase = this.tempTaxBases[index];
                if (currentBase.base === base) {
                    term = true;
                    currentBase.value = Big(currentBase.value).add(Big(value)).toString();
                }
            }
            if (!term) {
                this.tempTaxBases.push({
                    base: base,
                    value: Big(value).toString()
                });
            }
        },
        backClick() {
            if (window.history.length === 1) {
                window.close();
            } else {
                this.$router.back();
            }
        },
        saveClick() {
            if (this.offer.customer_id !== this.originalCustomerId) {
                this.saveAction();
            } else {
                if (!this.customerChanged) {
                    this.saveAction();
                } else {
                    let vm = this;
                    this.$LOCAL_BUS.TriggerEvent('popup', {
                        type: 'confirm',
                        text: this.$LANG.Get('alerts.customerChangesQuestionOffer'),
                        callback: (response) => {
                            if (response) {
                                vm.offer.customer_data = JSON.parse(JSON.stringify(vm.newCustomerData));
                                vm.customerChanged = false;
                            }
                            vm.saveAction();
                        }
                    });
                }
            }
        },
        saveAction() {
            let success = true;

            if (this.offer.customer_id == null) {
                success = false;
                this.offerValidation.customer_id = this.$LANG.Get('labels.customerIdMissing');
            } else {
                this.offerValidation.customer_id = '';
            }

            if (this.offer.valid_until == null) {
                success = false;
                this.offerValidation.valid_until = this.$LANG.Get('labels.validUntilMissing');
            } else {
                this.offerValidation.valid_until = '';
            }

            if ((this.offer.time_of_issue == null) || (!this.$HELPERS.DateTimePattern().test(this.offer.time_of_issue))) {
                success = false;
                this.timeOfIssueValidation.time = this.$LANG.Get('labels.timeOfIssueMissing');
            } else {
                this.timeOfIssueValidation.time = '';
            }

            let itemsValidation = '';
            if (this.offer.items.length === 0) {
                success = false;
                itemsValidation = this.$LANG.Get('labels.noItems');
            } else {
                itemsValidation = '';
            }
            let term = false;
            for (let item of this.offer.items) {
                if (item.description === '') {
                    term = true;
                }
            }
            if (term) {
                if (itemsValidation !== '') {
                    itemsValidation += '\n';
                }
                success = false;
                itemsValidation += this.$LANG.Get('labels.emptyItem');
            }
            this.offerValidation.items = itemsValidation;

            if (success) {
                let data = JSON.parse(JSON.stringify(this.offer));
                let vm = this;
                let action = 'post';
                let addition = '';
                if (this.mode === 'edit') {
                    action = 'put';
                    addition = '/' + this.offer.id;
                }
                this.$API.APICall(action, 'offers' + addition, data, (data, success) => {
                    if (success) {
                        vm.$CROSS_TAB_BUS.TriggerEvent('reload-offers', {});
                        if (action === 'post') {
                            vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        } else {
                            vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        }
                        if (vm.mode === 'add' || vm.mode === 'copy') {
                            vm.backClick();
                        }
                    } else {
                        if (data.error === 'invalid sequence later') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.offerSequenceLater'),
                                type: 'alert'
                            });
                        } else if (data.error === 'invalid sequence earlier') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.offerSequenceEarlier'),
                                type: 'alert'
                            });
                        } else if (data.error === 'invalid year') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.offerInvalidYear'),
                                type: 'alert'
                            });
                        } else {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.serverError'),
                                type: 'alert'
                            });
                        }
                    }
                });
            }
        },
        addItem() {
            if (this.selectedProduct === null) return;
            if (this.selectedProduct === -1) {
                let newItem = {
                    description: '',
                    price: 0,
                    quantity: 1,
                    tax: this.$store.state.settings.DEFAULT_TAX,
                    discount: 0,
                    measurement_unit_id: (this.$store.state.user.client.default_measurement_unit_id !== null) ? this.$store.state.user.client.default_measurement_unit_id : 1,
                    total: 0,
                    product_id: null
                }
                this.offer.items.push(newItem);
                this.$nextTick(() => {
                    this.selectedProduct = null;
                });
            } else {
                let vm = this;
                this.$API.APICall('get', 'products/' + this.selectedProduct, {}, (data, success) => {
                    if (success) {
                        let newItem = {
                            description: (data.product['name_' + vm.offer.locale] !== undefined) ? data.product['name_' + vm.offer.locale] : data.product.name,
                            price: data.product.price * this.offer.currency_rate,
                            quantity: 1,
                            tax: data.product.tax,
                            discount: 0,
                            measurement_unit_id: data.product.measurement_unit_id,
                            total: 0,
                            product_id: data.product.id
                        }
                        if (this.offer.time_of_issue < this.$store.state.settings.EUR_TRANSITION) {
                            newItem.price = data.product.price / this.offer.currency_rate;
                        }
                        vm.offer.items.push(newItem);
                        vm.selectedProduct = null;
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                }, false);
            }
        },
        removeItemClick(index) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
                    if (response) {
                        vm.offer.items.splice(index, 1);
                    }
                }
            });
        }
    },
    mounted() {
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-save',
                html: this.$LANG.Get('help.save'),
                left: -100,
                top: 0,
                width: 150
            },
            {
                class: 'help-back',
                html: this.$LANG.Get('help.back'),
                left: 85,
                top: -30,
                width: 300
            },
            {
                class: 'help-dates',
                html: this.$LANG.Get('help.dates'),
                left: 0,
                top: 0,
                width: 300
            },
            {
                class: 'help-item',
                html: this.$LANG.Get('help.item'),
                left: 300,
                top: -30,
                width: 300
            },
            {
                class: 'help-ppo',
                html: this.$LANG.Get('help.ppo'),
                left: 300,
                top: -50,
                width: 500
            }
        ]);

        this.$store.commit('selectMenu', 'offers');
        this.$store.commit('selectSubMenu', null);

        this.sequences = this.$store.state.user.offer_sequences;
        if (this.sequences.length > 0) {
            this.offer.offer_sequence_id = this.$store.state.user.default_offer_sequence_id;
        } else {
            this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
            this.$router.push('/');
        }

        if (this.mode === 'add') {
            this.$store.commit('setBreadcrumbs', [
                {
                    label: this.$LANG.Get('modules.offers'),
                    link: '/offers'
                },
                {
                    label: this.$LANG.Get('modules.newOffer'),
                    link: null
                }
            ]);

            this.offer.tax_system = this.$store.state.user.client.tax_system;
            let moment = require('moment');
            this.offer.time_of_issue = moment().format('YYYY-MM-DD HH:mm:ss');
            this.offer.valid_until = moment().add(this.$store.state.user.client.offer_valid_days, 'days').format('YYYY-MM-DD');
            this.offer.footer = this.$store.state.user.client['offer_footer_template_' + this.$store.state.locale];
            this.offer.locale = this.$store.state.locale;

            this.$nextTick(() => {
                this.loaded = true;
            });
        } else if (this.mode === 'edit') {
            let vm = this;
            this.$API.APICall('get', 'offers/' + this.offer.id, {}, (data, success) => {
                if (success) {
                    if (data.offer !== null) {
                        vm.offer.Fill(data.offer);
                        vm.customerChanged = data.offer.customer_changed;
                        vm.originalCustomerId = data.offer.customer_id;
                        vm.newCustomerData = data.offer.new_customer_data;
                        vm.$store.commit('setBreadcrumbs', [
                            {
                                label: vm.$LANG.Get('modules.offers'),
                                link: '/offers'
                            },
                            {
                                label: vm.offer.number,
                                link: null
                            }
                        ]);
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
                vm.$nextTick(() => {
                    vm.loaded = true;
                });
            });
        } else if (this.mode === 'copy') {
            let vm = this;
            this.$API.APICall('get', 'offers/' + this.offer.id, {}, (data, success) => {
                if (success) {
                    let moment = require('moment');
                    vm.offer.id = 0;
                    vm.offer.time_of_issue = moment().format('YYYY-MM-DD HH:mm:ss');
                    vm.offer.valid_until = moment().add(this.$store.state.user.client.offer_valid_days, 'days').format('YYYY-MM-DD');
                    vm.offer.customer_id = data.offer.customer_id;
                    vm.offer.currency = data.offer.currency;
                    if (vm.offer.time_of_issue >= this.$store.state.settings.EUR_TRANSITION && vm.offer.currency === 'HRK') {
                        vm.offer.currency = 'EUR';
                    }
                    let items = JSON.parse(JSON.stringify(data.offer.items));
                    for (let i = 0; i < items.length; i++) {
                        items[i].id = undefined;
                    }
                    vm.offer.items = items;
                    vm.offer.payment_method_id = data.offer.payment_method_id;
                    vm.offer.ppo = data.offer.ppo;
                    vm.offer.ppo_text = data.offer.ppo_text;
                    vm.offer.discount = data.offer.discount;
                    vm.offer.footer = data.offer.footer;
                    vm.offer.tax_system = vm.$store.state.user.client.tax_system;
                    vm.offer.locale = data.offer.locale;

                    this.$store.commit('setBreadcrumbs', [
                        {
                            label: this.$LANG.Get('modules.offers'),
                            link: '/offers'
                        },
                        {
                            label: this.$LANG.Get('modules.newOffer'),
                            link: null
                        }
                    ]);
                    vm.$nextTick(() => {
                        vm.loaded = true;
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        }
    }
}
</script>

<style scoped>
.form {
    max-width: 300px;
    margin-bottom: 10px;
}

.form-float {
    width: 300px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 15px;
}

.form-float h2 {
    margin: 0 0 5px;
}

.customer-details {
    margin: 5px;
}

.items-card {
    clear: both;
}

.items-container {
    overflow-x: auto;
    white-space: nowrap;
}

.footer-container {
    margin-top: 10px;
    width: calc(100% - 20px);
}

.item-select {
    max-width: 500px;
}

.items-table {
    width: 100%;
    min-width: 1000px;
}

.items-table tr:first-of-type td {
    font-size: 12px;
    padding-left: 5px;
}

.items-table-buttons {
    text-align: center;
}

.items-table-buttons span {
    cursor: pointer;
}

.summary-table {
    float: right;
    margin-top: 30px;
    width: 400px;
}

.bank-field {
    max-width: 400px;
}

@media only screen and (max-width: 500px) {
    .summary-table {
        float: none;
    }
}

</style>